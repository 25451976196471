import deepCopy from "../helpers/deep-copy";

let defaultProfile = {
  sub: null,
  givenName: null,
  familyName: null,
  nickname: null,
  name: null,
  picture: null,
  accessToken: null,
  idToken: null,
  expiresAt: null,
};

let hydratedProfile = deepCopy(defaultProfile);
if (localStorage) {
  hydratedProfile = {
    sub: localStorage.getItem("sub"),
    accessToken: localStorage.getItem("accessToken"),
    idToken: localStorage.getItem("idToken"),
    expiresAt: Number(localStorage.getItem("expiresAt")),
    familyName: localStorage.getItem("familyName"),
    givenName: localStorage.getItem("givenName"),
    name: localStorage.getItem("name"),
    nickname: localStorage.getItem("nickname"),
    picture: localStorage.getItem("picture"),
    updatedAt: localStorage.getItem("updatedAt"),
  };
}
const profile = (state = hydratedProfile, action) => {
  switch (action.type) {
    case "SET_PROFILE_ATTRIBUTES":
      return {
        ...state,
        ...action.attributes,
      };
    case "CLEAR_PROFILE":
      return deepCopy(defaultProfile);
    default:
      return state;
  }
};

export default profile;
