import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

/*
 * Row containing partlist information
 */
class PartListRow extends PureComponent {
  render = () => {
    return (
      <Link to={`/partlist/show/${this.props.id}`}>
        <div className={`partlist-row ${this.props.type}`}>
          <div className="partlist-number">
            {this.props.partListNumber || "null"}
          </div>
          <div className="job-orders">{this.props.jobOrders}</div>
          <div className="shipment-number">
            {this.props.shipmentNumber || "None"}
          </div>
        </div>
      </Link>
    );
  };
}

export default PartListRow;
