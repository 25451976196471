import { AppDbM2mApiUri } from "../config/host-info";

function QueryM2mApi(
  request,
  variable = null,
  successMsg = "Got data from M2M!",
  failureMsg = "Failed to get data from M2M.",
  variable2 = null
) {
  return new Promise((fulfill, reject) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    // Trigger loading icon
    this.setLoadingStatus();
    const variableRequest = variable == null ? "" : `&variable=${variable}`;
    const variableRequest2 = variable2 == null ? "" : `&variable2=${variable2}`;
    const endpoint = `${AppDbM2mApiUri}&request=${request}${variableRequest}${variableRequest2}`;

    function parse(parent) {
      return function () {
        parent.setLoadingStatus(this.responseText, successMsg, failureMsg);
        fulfill(JSON.parse(this.responseText));
      };
    }

    let oReq = new XMLHttpRequest();
    oReq.addEventListener("load", parse(this));
    oReq.open("GET", endpoint);
    oReq.send();
  });
}

export default QueryM2mApi;
