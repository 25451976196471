import React, { Component } from "react";

import { baseURI } from "../../config/host-info";
import apiQuery from "../../helpers/api-query";
import setLoadingStatus from "../../helpers/loading-status";
import isValidPartNumber from "../../helpers/valid-part";
import deepCopy from "../../helpers/deep-copy";

import Codes from "../presentational/Codes";
import Dropdown from "../presentational/Dropdown";
import TranslationRow from "../presentational/TranslationRow";
import SubmitBtn from "../presentational/SubmitBtn";
import Status from "../presentational/Status";
import { CustomsCode } from "../../helpers/Enums";
import PartInfo from "../presentational/PartInfo";

/*
 * The part show desktop component displays the part number,
 * translations, and codes for a given part, with the ability to
 * update this information using the RESTful API
 */
class PartShow extends Component {
  constructor(props) {
    super(props);
    this.codeDefaults = {
      ghs: {
        value: "",
        isNewCode: true,
        wasModified: false,
      },
      scheduleB: {
        value: "",
        isNewCode: true,
        wasModified: false,
      },
      TARIC: {
        value: "",
        isNewCode: true,
        wasModified: false,
      },
    };

    this.state = {
      partNumber: null,
      revisions: [],
      translations: [],
      focusedRevision: "",
      isLoading: null,
      fetchSuccess: null,
      fetchMsg: "",
      codes: deepCopy(this.codeDefaults),
    };

    this.setLoadingStatus = setLoadingStatus.bind(this);
    this.apiQuery = apiQuery.bind(this);
  }

  componentWillMount = async () => {
    this.preloadPartData();
    this.getLanguages();

    // User presses the back button
    window.onpopstate = () => {
      this.preloadPartData();
    };
  };

  preloadPartData = async () => {
    // Has the user entered a part URL?
    let partNum = window.location.hash.split(/[p=|r=]/)[2];
    let rev = window.location.hash.split(/[p=|r=]/)[4];
    if (!partNum) {
      return;
    }

    // If so, let's fetch information for that part
    this.setState(
      {
        partNumber: partNum,
        focusedRevision: rev,
      },
      async () => {
        // Gotta wait for revisions before you can get other shit like translations
        await this.getRevisions();
        this.getTranslations();
        this.getCodes();
        this.getPartDetails();
      }
    );
  };

  /*
   * Finds revisions for the given part
   */
  getRevisions = async () => {
    let startingPartNum = this.state.partNumber;

    let response = await this.apiQuery(
      `${baseURI}/${this.state.partNumber}/rev`,
      "GET",
      null,
      `Successfully found revisions for part ${this.state.partNumber}`,
      `Part ${this.state.partNumber} doesn't seem to exist`
    );

    // Only update state if we're recieving a response for the current part number
    if (startingPartNum !== this.state.partNumber) {
      return [];
    }

    let json = response.ok ? await response.json() : [];
    let focusedRevision = this.state.focusedRevision
      ? this.state.focusedRevision
      : null;

    /* Filter out obsolete revisions */
    json = json.filter((rev) => rev.obsolete == 0);
    if (json[0] && !this.state.focusedRevision) {
      // Sort these results by their...newness. New good. Old bad.
      json.sort((a, b) => Date.parse(a.created_at) < Date.parse(b.created_at));

      focusedRevision = json[0].revision;
    }
    this.setState({
      revisions: json,
      focusedRevision: focusedRevision,
      codes: deepCopy(this.codeDefaults),
      translations: [],
    });

    return response.ok ? json : [];
  };

  /*
   * Get a list of available languages for translation
   */
  getLanguages = async () => {
    let response = await this.apiQuery(
      `${baseURI}/language`,
      "GET",
      null,
      `Retrieved a list of languages`,
      `Could not get a list of languages from the server.`
    );
    let json = response.ok ? await response.json() : [];

    this.setState({ languages: json });
  };

  /*
   * Get details of a specific part (weight, cost each, etc)
   * @return { void }
   */
  getPartDetails = async () => {
    let partNumBeforeFetch = this.state.partNumber;

    if (!this.state.partNumber) {
      return;
    }

    // Get the data!
    let res = await this.apiQuery(
      `${baseURI}/${this.state.partNumber}/${this.state.focusedRevision}`,
      "GET",
      null,
      `Found detailed part information for part ${this.state.partNumber}`,
      `Failed to find detailed part information for part ${this.state.partNumber}`
    );
    res = res.ok ? await res.json() : {};

    // We can update with stale data if the user typed part number
    // doesn't match the API request's part number
    if (partNumBeforeFetch !== this.state.partNumber) {
      return;
    }

    // We should bind the user entered data to the part, so we can know upon saving if
    // the user has really edited a field
    let userFields = {
      userMaterial: res.userMaterial,
      userWeight: res.weight,
    };

    this.setState({
      partDetails: Object.assign(res, userFields),
    });
  };

  /*
   * Finds translations for the given part number/rev
   */
  getTranslations = async () => {
    // Only look for translations after we have a list of available languages
    if (!this.state.languages) {
      await this.getLanguages();
    }

    // Empty revision endpoint differs from the non-empty revision endpoint
    let uri =
      this.state.focusedRevision === ""
        ? "/translation"
        : `/${this.state.focusedRevision}/translation`;

    // If the user changes the partnumber before the fetch resolves, we don't
    // want to update the state with stale data. Make sure the part number we started fetching
    // equals the part number that's currently in state
    let partNumBeforeFetch = this.state.partNumber;
    let response = await this.apiQuery(
      `${baseURI}/${this.state.partNumber}${uri}`,
      "GET",
      null,
      `Found translations for part ${this.state.partNumber}`,
      `Found no translations for part ${this.state.partNumber}`
    );
    let json = response.ok ? await response.json() : [];

    // Add an empty translation onto the end, assuming languages exist
    if (this.state.languages.length > 0) {
      json.push({
        language: this.state.languages[0].code,
        description: "",
        extendedDescription: "",
        used_for: "",
        materials: "",
        newTranslation: true,
      });
    }

    // Update DOM
    if (partNumBeforeFetch === this.state.partNumber) {
      this.setState({ translations: json });
    }

    return;
  };

  /*
   * Delete a part translation
   * @param {string} lang - Language ('en', 'fr', etc) to delete
   */
  deleteTranslation = async (lang, i) => {
    // Can only do this if we have a list of languages
    if (!this.state.languages) {
      await this.getLanguages();
    }

    // If we're deleting a newly created (e.g. unsaved) language, there's
    // no need for API calls, just remove it from state
    if (this.state.translations[i].newTranslation) {
      // Remove the new translation of interest
      let newTranslations = Object.assign([], this.state.translations);
      newTranslations.splice(i, 1);

      // We only want a single fresh extra translation row, not many
      newTranslations = newTranslations.filter((translation) => {
        return (
          !translation.newTranslation ||
          (translation.newTranslation &&
            (translation.description !== "" ||
              translation.extendedDescription !== ""))
        );
      });
      newTranslations.push({
        language: this.state.languages[0].code,
        description: "",
        extendedDescription: "",
        newTranslation: true,
        wasModified: false,
      });

      // Update DOM and call it quits
      this.setState({ translations: newTranslations });
    }

    let confirmed = window.confirm(
      `Are you sure you want to delete the ${lang} translation?`
    );
    if (confirmed) {
      // Otherwise, we need API calls
      let endpoint = `${baseURI}/${this.state.partNumber}/translation/${lang}`;
      if (this.state.focusedRevision !== "") {
        endpoint = `${baseURI}/${this.state.partNumber}/${this.state.focusedRevision}/translation/${lang}`;
      }
      let response = await this.apiQuery(
        endpoint,
        "DELETE",
        null,
        `Successfully deleted ${lang} translation for part ${this.state.partNumber}`,
        `Failed to delete ${lang} translation for part ${this.state.partNumber}`
      );

      // If it worked, lets update the DOM to remove the deleted translation
      if (response.ok) {
        let modifiedTranslations = Object.assign([], this.state.translations);
        modifiedTranslations = modifiedTranslations.filter((translation) => {
          return (
            translation.language !== lang ||
            (translation.wasModified &&
              translation.description !== "" &&
              translation.extendedDescription !== "")
          );
        });
        modifiedTranslations.push({
          language: this.state.languages[0].code,
          description: "",
          extendedDescription: "",
          newTranslation: true,
          wasModified: false,
        });
        this.setState({ translations: modifiedTranslations });
      }
    }
  };

  /*
   * Finds part codes for the given part number
   * @return { void }
   */
  getCodes = async () => {
    // The endpoint for the empty string revision is different than
    // for non-empty strings, so we need to account for that
    if (!this.state.partNumber || this.state.focusedRevision === null) {
      return;
    }
    let revisionUrl = "";
    if (this.state.focusedRevision !== "") {
      revisionUrl = `/${this.state.focusedRevision}`;
    }
    let partNumBeforeFetch = this.state.partNumber;
    let endpoint = `${baseURI}/${this.state.partNumber}${revisionUrl}/code`;

    let response = await this.apiQuery(
      endpoint,
      "GET",
      null,
      `Successfully found codes for part ${this.state.partNumber}`,
      `Failed to find codes for part ${this.state.partNumber}`
    );

    // We can update with stale data if the user typed part number
    // doesn't match the API request's part number
    if (partNumBeforeFetch !== this.state.partNumber) {
      return;
    }

    let json = response.ok ? await response.json() : [];

    /* This hurts my soul, but Object.assign doesn't actually deep copy stuff, which
     * causes bugs, so...here we are. :( */
    let newCodes = deepCopy(this.codeDefaults);

    for (let code of json) {
      switch (code.type) {
        case 0:
          newCodes.ghs.value = code.value;
          newCodes.ghs.isNewCode = false;
          break;
        case 10:
          newCodes.scheduleB.value = code.value;
          newCodes.scheduleB.isNewCode = false;
          break;
        case 20:
          newCodes.TARIC.value = code.value;
          newCodes.TARIC.isNewCode = false;
          break;
      }
    }

    this.setState({ codes: newCodes });
  };

  /*
   * Save a given translation for the currently selected part
   * @param { number } id - Which translation index in the React array
   * state should we save?
   */
  saveTranslation = async (id) => {
    // The endpoint for emptry string revision parts differs
    // from non-empty string revision parts. :(
    let revisionUrl = "";
    if (this.state.focusedRevision !== "") {
      revisionUrl = `/${this.state.focusedRevision}`;
    }
    let endpoint = `${baseURI}/${this.state.partNumber}${revisionUrl}/translation/${this.state.translations[id].language}`;

    let method = this.state.translations[id].newTranslation ? "POST" : "PATCH";
    let body = JSON.stringify({
      description: this.state.translations[id].description,
      extendedDescription: this.state.translations[id].extendedDescription,
      used_for: this.state.translations[id].used_for,
      materials: this.state.translations[id].materials,
    });

    let response = await this.apiQuery(
      endpoint,
      method,
      body,
      `Successfully saved ${this.state.translations[id].language} translation for part ${this.state.partNumber}`,
      `Failed to save ${this.state.translations[id].language} translation for part ${this.state.partNumber}`
    );
    let json = response.ok ? await response.json() : null;
    if (json) {
      let newTranslations = Object.assign([], this.state.translations);
      newTranslations[id] = json;
      this.setState({ translations: newTranslations });
    }

    return response;
  };

  /*
   * POST and PATCH all translations that have changed
   */
  saveAllTranslations = async () => {
    let translationsToUpdate = [];
    for (let i = 0; i < this.state.translations.length; i++) {
      if (
        this.state.translations[i].wasModified &&
        this.state.translations[i].language !== "" &&
        this.state.translations[i].description !== ""
      ) {
        translationsToUpdate.push(this.saveTranslation(i));
      }
    }
    if (translationsToUpdate.length === 0) {
      return;
    }

    return await Promise.all(translationsToUpdate);
  };

  /*
   * Save a given code for the currently selected part
   * @param { string } id - Which code (e.g. ghc, scheduleB, TARIC) to save
   */
  saveCode = async (id) => {
    let revisionUrl = "";
    if (this.state.focusedRevision !== "") {
      revisionUrl = `/${this.state.focusedRevision}`;
    }
    let customsCodeId = CustomsCode.get(id);
    let endpoint = `${baseURI}/${this.state.partNumber}${revisionUrl}/code/${customsCodeId.value}`;

    // Are we POSTing or PATCHing? Only POST new codes
    let method = this.state.codes[id].isNewCode ? "POST" : "PATCH";
    let body = JSON.stringify({
      value: this.state.codes[id].value,
    });

    let response = await this.apiQuery(
      endpoint,
      method,
      body,
      `Successfully saved ${id} code for part ${this.state.partNumber}`,
      `Failed to save ${id} code for part ${this.state.partNumber}`
    );

    if (response.ok && this.state.codes[id].isNewCode) {
      let codes = Object.assign({}, this.state.codes);
      codes[id].isNewCode = false;
      this.setState({ codes });
    }
    return response.ok ? await response.json() : null;
  };

  /*
   * Save part codes
   * @return { void }
   */
  saveCodes = async () => {
    let codesToSave = [];
    this.state.codes["ghs"].wasModified
      ? codesToSave.push(this.saveCode("ghs"))
      : null;
    this.state.codes["scheduleB"].wasModified
      ? codesToSave.push(this.saveCode("scheduleB"))
      : null;
    this.state.codes["TARIC"].wasModified
      ? codesToSave.push(this.saveCode("TARIC"))
      : null;

    return await Promise.all(codesToSave);
  };

  /*
   * Save part details
   * @return { void }
   */
  savePartDetails = async () => {
    // Only record fields which the user has changed
    let body = {};
    if (
      this.state.partDetails.userMaterial &&
      this.state.partDetails.userMaterial !== ""
    ) {
      body.material = this.state.partDetails.userMaterial;
    }

    if (
      this.state.partDetails.userWeight &&
      Number(this.state.partDetails.userWeight) !==
        this.state.partDetails.weight
    ) {
      body.weight = Number(this.state.partDetails.userWeight);
    }

    if (this.state.partDetails.userDescription) {
      body.description = this.state.partDetails.userDescription;
    }
    if (this.state.partDetails.userExtendedDescription) {
      body.extendedDescription = this.state.partDetails.userExtendedDescription;
    }

    if (this.state.partDetails.userCostEach) {
      body.costEach = this.state.partDetails.userCostEach;
    }

    // No point hitting API if there's nothing to PATCH
    if (Object.keys(body).length === 0) {
      return;
    }

    // Null revision fix :(
    if (!this.state.partNumber) {
      return;
    }
    let revisionUrl = "";
    if (this.state.focusedRevision !== "") {
      revisionUrl = `/${this.state.focusedRevision}`;
    }

    await this.apiQuery(
      `${baseURI}/${this.state.partNumber}${revisionUrl}`,
      "PATCH",
      JSON.stringify(body),
      `Successfully saved detailed part information for part ${this.state.partNumber}`,
      `Failed to save detailed part information for part ${this.state.partNumber}`
    );
  };

  /*
   * Edit translation fields
   * @param { Object } value - What the user typed
   * @param { Object } id - Which translation will change
   * @param { string } field - Which translation field was edited?
   * @return { void }
   */
  editTranslation = (value, id, field) => {
    // Deep copy previous translations
    let newTranslations = [];
    for (let i = 0; i < this.state.translations.length; i++) {
      newTranslations.push({
        language: this.state.translations[i].language,
        description: this.state.translations[i].description,
        extendedDescription: this.state.translations[i].extendedDescription,
        used_for: this.state.translations[i].used_for,
        materials: this.state.translations[i].materials,
        newTranslation: this.state.translations[i].newTranslation,
        wasModified: this.state.translations[i].wasModified,
      });

      // Now modify the translation that the user typed in
      if (i === id) {
        newTranslations[i][field] = value;
        newTranslations[i]["wasModified"] = true;
      }
    }

    // Add a new translation if the user is editing the last translation row
    if (id === this.state.translations.length - 1) {
      newTranslations.push({
        language: this.state.languages[0].code,
        description: "",
        extendedDescription: "",
        used_for: "",
        materials: "",
        newTranslation: true,
        wasModified: false,
      });
    }

    this.setState({
      translations: newTranslations,
    });
  };

  /*
   * Edit code fields
   * @param { Object } value - What the user typed
   * @param { Object } id - Which code will change
   */
  editCodes = (value, id) => {
    let nextCodes = Object.assign({}, this.state.codes);
    nextCodes[id].value = value;
    nextCodes[id].wasModified = true;
    this.setState({ codes: nextCodes });
  };

  /*
   * Edit part number
   * @param { Object } value - What the user typed
   */
  editPartNumber = async (value) => {
    this.setState(
      {
        partNumber: value,
        revisions: [],
        focusedRevision: null,
        translations: [],
        codes: {
          ghs: {
            value: "",
            isNewCode: true,
            wasModified: false,
          },
          scheduleB: {
            value: "",
            isNewCode: true,
            wasModified: false,
          },
          TARIC: {
            value: "",
            isNewCode: true,
            wasModified: false,
          },
        },
        partDetails: null,
      },
      async () => {
        if (!isValidPartNumber(value)) {
          return;
        }

        let res = await this.getRevisions();
        // Only get translations/codes if the part has revisions
        // Similarly only update the URL hash for a valid part
        if (res.length !== 0) {
          this.props.history.push(
            `/part#p=${this.state.partNumber}r=${this.state.focusedRevision}`
          );

          this.getTranslations();
          this.getCodes();
          this.getPartDetails();
        } else {
          this.setState({ focusedRevision: "" });
          this.props.history.push(`/part`);
        }
      }
    );
  };

  /*
   * User modifies an editable field in the detailed parts info panel
   * @param {string} field - Key edited (weight, material, etc)
   * @return {void}
   */
  editPartDetails = (field, value) => {
    let newPartDetails = Object.assign({}, this.state.partDetails);
    newPartDetails[field] = value;

    this.setState({ partDetails: newPartDetails });
  };

  render = () => {
    return (
      <div className="part-show">
        <Status
          isLoading={this.state.isLoading}
          success={this.state.fetchSuccess}
          message={this.state.fetchMsg}
        />
        <div className="part-search">
          <label className="part-title">Part {this.state.partNumber}</label>
          <input
            className="part-number"
            onChange={(e) => {
              this.editPartNumber(e.target.value);
            }}
            value={this.state.partNumber || ""}
          />
          <Dropdown
            type="revision"
            options={this.state.revisions
              .filter((revision) => !revision.obsolete)
              .map((revision) => revision.revision)}
            onChange={(e) => {
              this.setState({ focusedRevision: e.target.value }, () => {
                this.getTranslations();
                this.getCodes();
                this.getPartDetails();
                this.props.history.push(
                  `/part#p=${this.state.partNumber}r=${this.state.focusedRevision}`
                );
              });
            }}
          />
        </div>
        <div className="translations-codes-container">
          <div className="translations">
            <label className="translations-title">Translations</label>
            {this.state.translations.length === 0
              ? "There doesn't seem to be anything here..."
              : ""}
            {this.state.translations.map((translation, i) => {
              return (
                <TranslationRow
                  language={translation.language}
                  languages={this.state.languages}
                  description={translation.description}
                  extendedDescription={translation.extendedDescription}
                  usedFor={translation.used_for}
                  materials={translation.materials}
                  key={i}
                  index={i}
                  editTranslation={this.editTranslation}
                  deleteTranslation={(lang, i) => {
                    this.deleteTranslation(lang, i);
                  }}
                  newTranslation={translation.newTranslation}
                />
              );
            })}
            <SubmitBtn
              type="save"
              className="save-translations"
              onClick={() => {
                this.saveAllTranslations();
              }}
            >
              Save
            </SubmitBtn>
          </div>
          <div className="codes-container">
            <Codes
              ghs={this.state.codes.ghs.value || ""}
              scheduleB={this.state.codes.scheduleB.value || ""}
              TARIC={this.state.codes.TARIC.value || ""}
              editCodes={this.editCodes}
            />
            <SubmitBtn
              type="save"
              className="save-codes"
              onClick={this.saveCodes}
            >
              Save
            </SubmitBtn>
          </div>
        </div>
        {/* Only display part details if we've fetched a part */}
        {this.state.partDetails ? (
          <PartInfo
            number={this.state.partDetails.number}
            desc={
              this.state.partDetails.userDescription ||
              this.state.partDetails.description
            }
            extendedDesc={
              this.state.partDetails.userExtendedDescription ||
              this.state.partDetails.extendedDescription
            }
            cost={
              this.state.partDetails.userCostEach ||
              this.state.partDetails.costEach
            }
            weight={
              this.state.partDetails.userWeight || this.state.partDetails.weight
            }
            material={
              this.state.partDetails.userMaterial ||
              this.state.partDetails.material
            }
            editPartDetails={this.editPartDetails}
            savePartDetails={this.savePartDetails}
          />
        ) : null}
      </div>
    );
  };
}

export default PartShow;
