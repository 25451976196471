import React, { PureComponent } from "react";

/*
 * Drop down menu
 */
class Dropdown extends PureComponent {
  render = () => {
    const values = this.props.options.map((option) => {
      return (
        <option
          value={option}
          key={option}
          selected={this.props.selected === option}
        >
          {option}
        </option>
      );
    });

    return (
      <div className={`dropdown-menu ${this.props.type}`}>
        <select
          onChange={this.props.onChange}
          value={this.props.value || undefined}
        >
          {values}
        </select>
      </div>
    );
  };
}

export default Dropdown;
