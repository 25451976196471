/**
 * Add/override redirect URI for later consumption
 * @param uri Redirect location
 * @returns void
 */
export const pushRedirect = (uri) => {
  if (!localStorage) return;
  localStorage.setItem("redirectURI", uri);
};

/**
 * Redirect user to persisted resource
 * @returns void
 */
export const popRedirect = () => {
  if (!localStorage) return;
  let uri = localStorage.getItem("redirectURI");
  if (!uri) return;

  localStorage.removeItem("redirectURI");
  window.location.replace(uri);
};
