import React, { Component } from "react";
import { Route } from "react-router-dom";

import ShipmentIndex from "./ShipmentIndex";
import ShipmentCreate from "./ShipmentCreate";
import ShipmentShow from "./ShipmentShow";
import ShipmentLabels from "./ShipmentLabels";

/*
 * Container for shipment pages
 */
class Shipment extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <div className="shipment-base">
        <Route exact path="/shipment" component={ShipmentIndex} />
        <Route exact path="/shipment/create" component={ShipmentCreate} />
        <Route exact path="/shipment/show/:id" component={ShipmentShow} />
      </div>
    );
  };
}

export default Shipment;
