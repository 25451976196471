/* npm imports */
import React, { Component } from "react";

import Status from "../presentational/Status";

/* Helper imports */
import { baseURI, BCGCodeURI, wassonLogo } from "../../config/host-info";
import setLoadingStatus from "../../helpers/loading-status";
import apiQuery from "../../helpers/api-query";

/* presentational component imports */
import PackedPartLabel from "../presentational/PackedPartLabel";

/*
 * Contains details of a given shipment
 */
class PartListLabels extends Component {
  constructor(props) {
    super(props);
    this.setLoadingStatus = setLoadingStatus.bind(this);
    this.apiQuery = apiQuery.bind(this);

    this.state = {};
  }

  componentDidMount = async () => {
    this.getRequiredParts();
  };

  getRequiredParts = async () => {
    let res = await this.apiQuery(
      `${baseURI}/partlist/${this.props.match.params.id}/requiredpart`,
      "GET",
      null,
      ``,
      ``
    );
    let requiredParts = res.ok ? await res.json() : [];

    this.setState({ requiredParts });
  };

  render = () => (
    <div className="part-list-labels">
      {this.state.requiredParts ? (
        this.state.requiredParts.map((p, i) => (
          <PackedPartLabel
            api={BCGCodeURI}
            wassonLogo={wassonLogo}
            partNumber={p.part.number}
            description={p.part.description}
            key={i}
          />
        ))
      ) : (
        <div>No parts for this PartList...</div>
      )}
    </div>
  );
}

export default PartListLabels;
