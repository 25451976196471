import React, { PureComponent } from "react";

import TabBtn from "./TabBtn";
import ShipmentRow from "./ShipmentRow";
import { Status } from "../../helpers/Enums";

/*
 * Contains a list of tabs which the user can filter items by
 */
class ShipmentTabMenu extends PureComponent {
  populateTabs = () => {
    let tabNames = ["all", "started", "partial", "complete", "pending"];
    let tabs = tabNames.map((tabName) => {
      return (
        <TabBtn
          type={tabName}
          key={tabName}
          active={this.props.activeTab === tabName}
          onClick={this.props.onClick}
        />
      );
    });

    return tabs;
  };

  populateRows = () => {
    // We need to filter only available values
    let availableValues = Object.assign([], this.props.values);

    if (this.props.activeTab !== "all") {
      availableValues = availableValues.filter(
        (value) =>
          Status.get(Number(value.status)).key.toLowerCase() ===
          this.props.activeTab
      );
    }

    let rows = availableValues.map((value) => {
      return (
        <ShipmentRow
          type={Status.get(Number(value.status)).key.toLowerCase()}
          shipmentNumber={value.id}
          jobOrders={value.jos}
          date={value.created_at}
          key={value.id}
          id={value.id}
        />
      );
    });

    return rows;
  };

  render = () => {
    return (
      <div className="shipment-tab-menu">
        <div className="tabs">{this.populateTabs()}</div>
        <div className={`tab-body ${this.props.activeTab || "all"}`}>
          <div className="labels">
            <div id="id">#</div>
            <div id="jos">Job Orders</div>
            <div id="ship-by">Ship By</div>
          </div>
          {this.populateRows()}
        </div>
      </div>
    );
  };
}

export default ShipmentTabMenu;
