import React, { PureComponent } from "react";

import "./CustomsCodesBadge.css";

/*
 * Drop down menu
 * @prop {boolean} missingTranslations - What translations are missing from
 * the part associated with this badge?
 */
class CustomsCodesBadge extends PureComponent {
  isMissingCustomsCodes = () => {
    return (
      this.props.missingCustomsCodes &&
      this.props.missingCustomsCodes.length > 0
    );
  };

  hasOneOrMoreCustomsCodes = () => {
    return this.props.codes && this.props.codes.length > 1;
  };

  render = () => {
    let transStr = "This part has all customs codes!";
    if (this.isMissingCustomsCodes()) {
      transStr = `This part is missing the following customs codes: ${this.props.missingCustomsCodes.join(
        ", "
      )}`;
    }

    return (
      <div
        className={`badge customs-codes-badge ${
          this.isMissingCustomsCodes()
            ? this.hasOneOrMoreCustomsCodes()
              ? "missing-partial-codes"
              : "missing-codes"
            : ""
        }`}
        title={transStr}
      >
        C
      </div>
    );
  };
}

export default CustomsCodesBadge;
