import React, { PureComponent } from "react";

/*
 * Form tab button
 */
class TabBtn extends PureComponent {
  render = () => {
    let active = this.props.active ? "active" : "";
    let activeTriangle = (
      <div className={`triangle ${this.props.type} ${active}`} />
    );
    if (!this.props.active) {
      activeTriangle = null;
    }
    return (
      <div
        className={`tab-btn ${this.props.type} ${active}`}
        onClick={() => {
          this.props.onClick(this.props.type);
        }}
      >
        <div className="text">
          {this.props.type.charAt(0).toUpperCase() + this.props.type.slice(1)}
        </div>
        {activeTriangle}
      </div>
    );
  };
}

export default TabBtn;
