import auth0, { Auth0DecodedHash } from "auth0-js";
import { popRedirect } from "./redirect";
import { auth } from "../config/host-info";

let webAuth = new auth0.WebAuth(auth);

export const login = () => webAuth.authorize();

export const parseAuthorizationHash = () => {
  return new Promise((fulfill, reject) => {
    webAuth.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        fulfill({
          accessToken: authResult.accessToken,
          idToken: authResult.idToken,
          expiresAt: JSON.stringify(
            (authResult &&
              authResult.expiresIn &&
              authResult.expiresIn * 1000 + new Date().getTime()) ||
              null
          ),
        });
      } else if (err) {
        reject(err);
      }
    });
  });
};

export const parseProfile = (accessToken) => {
  return new Promise((fulfill, reject) => {
    if (!accessToken) reject();
    webAuth.client.userInfo(accessToken || "", (err, profile) => {
      if (profile) {
        fulfill(profile);
      } else {
        reject(err);
      }
    });
  });
};

export const saveProfileState = (state) => {
  if (!localStorage) return;

  Object.keys(state).map((key) => localStorage.setItem(key, state[key]));
};

export const clearLocalProfileState = () => {
  if (!localStorage) return;
  const profileKeys = [
    "accessToken",
    "idToken",
    "expiresAt",
    "familyName",
    "givenName",
    "name",
    "nickname",
    "picture",
    "sub",
    "updatedAt",
  ];

  profileKeys.map((key) => localStorage.removeItem(key));
  popRedirect();
};

export const isAuthenticated = () => {
  // Check whether the current time is past the
  // Access Token's expiry time
  let expiresAt = JSON.parse(localStorage.getItem("expires_at") || "");
  return new Date().getTime() < expiresAt;
};

export const getIdToken = () => {
  const accessToken = localStorage.getItem("idToken");
  if (!accessToken) {
    throw new Error("No ID Token found");
  }
  return accessToken;
};

export const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};

export const getProfile = () => {
  //@ts-ignore
  let accessToken = this.getAccessToken();
  webAuth.client.userInfo(accessToken, (err, profile) => {
    if (!profile) return undefined;
    //@ts-ignore
    this.userProfile = profile;

    return profile;
  });
};

export const getAuthHeader = () => ({
  Authorization: `Bearer ${getAccessToken()}`,
});

export { webAuth };
