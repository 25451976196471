import * as React from "react";
import { connect } from "react-redux";
import { webAuth, parseProfile, saveProfileState } from "../../helpers/auth";
import { Auth0Error, Auth0DecodedHash, Auth0UserProfile } from "auth0-js";
import { auth } from "../../config/host-info";

class SingleSignIn extends React.Component {
  /* Function to run on each authorization attempt */
  reauthorize;
  /* How often to refresh tokens, 15 minutes is the recommended magic number 🤷 */
  refreshInterval = 1000 * 60 * 15;

  componentDidMount = async () => {
    this.reauthorize = setInterval(this.refreshAccess, this.refreshInterval);
  };

  componentWillUnmount = () => {
    clearInterval(this.reauthorize);
  };

  refreshAccess = () => {
    const { currentProfile } = this.props;
    /* We should only be refreshing access if there's a profile signed in */
    if (
      !currentProfile ||
      !currentProfile.expiresAt ||
      currentProfile.expiresAt < Date.now()
    ) {
      return;
    }
    webAuth.checkSession(auth, async (err, authResult) => {
      if (err) return;
      let parsedProfile = await parseProfile(authResult.accessToken);
      let profile = {
        familyName: parsedProfile.family_name,
        givenName: parsedProfile.given_name,
        name: parsedProfile.name,
        nickname: parsedProfile.nickname,
        picture: parsedProfile.picture,
        sub: parsedProfile.sub,
        updatedAt: parsedProfile.updated_at,
        expiresAt: Date.now() + Number(authResult.expiresIn) * 1000,
      };
      this.props.updateProfile({
        ...authResult,
        ...profile,
      });

      /* Now persist this information in local storage */
      saveProfileState({ ...authResult, ...profile });
    });
  };

  render = () => null;
}

const mapState = (state) => ({
  currentProfile: state.profile,
});
const mapDispatch = (dispatch) => ({
  updateProfile: (attributes) => {
    dispatch({
      type: "SET_PROFILE_ATTRIBUTES",
      attributes,
    });
  },
});

export default connect(mapState, mapDispatch)(SingleSignIn);
