/* npm imports */
import React, { Component } from "react";

import Status from "../presentational/Status";

/* Helper imports */
import { baseURI, BCGCodeURI, wassonLogo } from "../../config/host-info";
import setLoadingStatus from "../../helpers/loading-status";
import apiQuery from "../../helpers/api-query";

/* presentational component imports */
import PackedPartLabel from "../presentational/PackedPartLabel";

/*
 * Contains details of a given shipment
 */
class ShipmentLabels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      fetchSuccess: null,
      id: this.props.match.params.id,
      partLists: [],
      requiredParts: [],
      packedParts: [],
      pallets: [],
      boxes: [],
    };

    this.apiQuery = apiQuery.bind(this);
    this.setLoadingStatus = setLoadingStatus.bind(this);
  }

  /*
   * Immediately after the component mounts, we should fetch the
   * PartLists associated with this shipment, and the required parts
   * associated with each PartList.
   */
  componentDidMount = async () => {
    this.getPackedParts();
  };

  /*
   * Get the packed parts for a given shipment
   * @param { string } id - PartList id
   */
  getPackedParts = async () => {
    let res = await this.apiQuery(
      `${baseURI}/shipment/${this.state.id}`,
      "GET",
      null,
      "Successfully found the packed parts for this shipment.",
      "Failed to find the packed parts for this shipment."
    );
    if (!res.ok) {
      return;
    }

    let json = await res.json();
    let packedParts = [];
    let allBoxes = [];
    for (let pallet of json.pallets) {
      for (let box of pallet.boxes) {
        allBoxes.push(box);
        for (let packedPart of box.packed_parts) {
          packedPart["boxLabel"] = box.label;
          packedPart["palletName"] = pallet.name;
          packedParts.push(Object.assign({}, packedPart, { userToUnpack: "" }));
        }
      }
    }

    this.setState({
      packedParts: packedParts,
      allBoxes: allBoxes,
    });
  };

  render = () => {
    // Maybe build into the same app someday
    return (
      <div className="shipment-labels">
        {this.state.packedParts.map((packedPart) => {
          if (packedPart.packed_qty == 0) return;

          let packedPartLabels = [];
          let numBags = 1;
          if (packedPart.requiredpart.qty_per_bag != 0) {
            numBags =
              Number(packedPart.packed_qty) /
              Number(packedPart.requiredpart.qty_per_bag);
          }
          for (let i = 0; i < numBags; i++) {
            packedPartLabels.push(
              <PackedPartLabel
                api={BCGCodeURI}
                wassonLogo={wassonLogo}
                partNumber={packedPart.requiredpart.part.number}
                description={packedPart.requiredpart.part.description}
                item={packedPart.item_number}
                pallet_name={packedPart.palletName}
                box_label={packedPart.boxLabel}
                key={`${packedPart.requiredpart.part.number}-bag-${i}`}
                qty={Number(packedPart.packed_qty) / Number(numBags)}
              />
            );
          }

          return packedPartLabels;
        })}
      </div>
    );
  };
}

export default ShipmentLabels;
