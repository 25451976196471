import React, { Component } from "react";

import { baseURI } from "../../config/host-info";
import apiQuery from "../../helpers/api-query";
import setLoadingStatus from "../../helpers/loading-status";

import SubmitBtn from "../presentational/SubmitBtn";
import { Status } from "../../helpers/Enums";

/*
 * The ship list create allows the creation of new ship lists
 */
class ShipmentCreate extends Component {
  constructor(props) {
    super(props);

    this.state = { shipDate: "" };

    this.apiQuery = apiQuery.bind(this);
    this.setLoadingStatus = setLoadingStatus.bind(this);
  }

  /*
   * User edits the jos field
   * @param { string } value - User entered text
   */
  editShipDate = (value) => {
    let d = new Date(value);
    this.setState({ shipDate: d.toISOString() });
  };

  /*
   * User clicks create shipment
   */
  createShipment = async () => {
    let endpoint = `${baseURI}/shipment`;
    let body = JSON.stringify({
      ship_by_date: this.state.shipDate.replace("Z", ""),
      status: Status.get("started").value,
    });

    let res = await this.apiQuery(
      endpoint,
      "POST",
      body,
      ``,
      `Failed to create a shipment.`
    );

    if (res.ok) {
      let json = await res.json();
      this.props.history.push(`/shipment/show/${json.id}`);
    }
  };

  render = () => {
    return (
      <div className="shipment-create">
        <label className="create-shipment-title">Create Shipment</label>
        <label className="ship-date-title">Ship Date</label>
        <input
          className="date-input"
          type="date"
          onChange={(e) => {
            this.editShipDate(e.target.value);
          }}
        />
        <SubmitBtn
          type="create"
          onClick={() => {
            this.createShipment();
          }}
        >
          Create
        </SubmitBtn>
      </div>
    );
  };
}

export default ShipmentCreate;
