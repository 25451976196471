import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

/*
 * Row containing shipment information
 */
class ShipmentRow extends PureComponent {
  render = () => {
    return (
      <Link to={`/shipment/show/${this.props.id}`}>
        <div className={`shipment-row ${this.props.type}`}>
          <div className="shipment-number">
            {this.props.shipmentNumber || "null"}
          </div>
          <div className="job-orders">{this.props.jobOrders}</div>
          <div className="date">{this.props.date}</div>
        </div>
      </Link>
    );
  };
}

export default ShipmentRow;
