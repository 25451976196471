import React, { PureComponent } from "react";

/*
 * Drop down menu
 * @prop {boolean} missingTranslations - What translations are missing from
 * the part associated with this badge?
 */
class TranslationBadge extends PureComponent {
  isMissingTranslations = () => {
    return (
      this.props.missingTranslations &&
      this.props.missingTranslations.length > 0
    );
  };

  render = () => {
    let transStr = "This part has translations for every relevant language!";
    if (this.isMissingTranslations()) {
      transStr = `This part is missing translations for the following languages: ${this.props.missingTranslations.join(
        ", "
      )}`;
    }

    return (
      <div
        className={`badge ${
          this.isMissingTranslations() ? "missing-translations" : ""
        }`}
        title={transStr}
      >
        T
      </div>
    );
  };
}

export default TranslationBadge;
