import React, { PureComponent } from "react";

/*
 * Form submission button used to submit API calls within the relevant a form
 * (edit, delete, etc.)
 */
class SubmitBtn extends PureComponent {
  render = () => {
    return (
      <button
        className={`form-submission-btn ${this.props.type}-btn ${
          this.props.disabled ? "disabled" : ""
        }`}
        onClick={() => {
          if (this.props.onClick && !this.props.disabled) {
            this.props.onClick();
          }
        }}
      >
        {this.props.children}
      </button>
    );
  };
}

export default SubmitBtn;
