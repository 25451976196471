import deepCopy from "../helpers/deep-copy";

let defaultNewPart = {
  number: "F",
  revision: "",
  description: "",
  extendedDescription: "",
  material: "",
  usedFor: "",
  countryOfOrigin: "",
  costEach: "",
  extendedCost: "",
  commercialValue: "",
  weight: "",
  unitOfMeasure: "EA",
};

let initialNewPart = deepCopy(defaultNewPart);
const newPart = (state = initialNewPart, action) => {
  switch (action.type) {
    case "SET_NEW_PART_ATTRIBUTE":
      return {
        ...state,
        ...action.attribute,
      };
    case "CLEAR_NEW_PART":
      return deepCopy(defaultNewPart);
    default:
      return state;
  }
};

export default newPart;
