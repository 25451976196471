import * as React from "react";
import { connect } from "react-redux";

import {
  parseAuthorizationHash,
  parseProfile,
  saveProfileState,
} from "../../helpers/auth";
import history from "../../helpers/history";
import { popRedirect } from "../../helpers/redirect";

class Login extends React.PureComponent {
  componentDidMount = async () => {
    let authResult = await parseAuthorizationHash();
    if (authResult) {
      if (!authResult || !authResult.accessToken) return;
      let profile = await parseProfile(authResult.accessToken);
      let parsedProfile = {
        familyName: profile.family_name,
        givenName: profile.given_name,
        name: profile.name,
        nickname: profile.nickname,
        picture: profile.picture,
        sub: profile.sub,
        updatedAt: profile.updated_at,
        //@ts-ignore
        expiresAt: Number(authResult.expiresAt),
      };

      this.props.updateProfile({
        ...authResult,
        ...parsedProfile,
      });

      /* Now persist this information in local storage */
      saveProfileState({ ...authResult, ...profile });
    }

    history.replace("/");

    /* Visit user's last context if it exists */
    popRedirect();
  };

  render = () => {
    const {} = this.props;
    return <React.Fragment />;
  };
}

//@ts-ignore
const mapDispatch = (dispatch) => ({
  updateProfile: (attributes) => {
    dispatch({
      type: "SET_PROFILE_ATTRIBUTES",
      attributes,
    });
  },
});

export default connect(null, mapDispatch)(Login);
