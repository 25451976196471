import React from "react";
import { Link } from "react-router-dom";
import apiQuery from "../../../helpers/api-query";
import setLoadingStatus from "../../../helpers/loading-status";
import { baseURI } from "../../../config/host-info";
import "./PickList.css";
import PickListRow from "./PickListRow";
import Status from "../../presentational/Status";

export default class PickList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      reducedRequiredParts: [],
      partLists: [],
    };

    this.apiQuery = apiQuery.bind(this);
    this.setLoadingStatus = setLoadingStatus.bind(this);
  }

  componentDidMount = async () => {
    this.findReducedRequiredParts(this.props.match.params.id);
  };

  findReducedRequiredParts = async (id) => {
    if (!id) return;
    let reducedRequiredParts = await this.getReducedRequiredParts(id);
    if (!reducedRequiredParts) return;
    this.setReducedRequiredPartsState(reducedRequiredParts);
  };

  setReducedRequiredPartsState = (reducedRequiredParts) => {
    let partLists = {};
    reducedRequiredParts.forEach((reducedRequiredPart) =>
      Object.keys(reducedRequiredPart.partLists).forEach(
        (partListKey) =>
          (partLists[partListKey] = reducedRequiredPart.partLists[partListKey])
      )
    );
    this.setState({
      id: Number(this.props.match.params.id),
      reducedRequiredParts,
      partLists,
    });
  };

  render = () => {
    return (
      <div id="picklist">
        <Status
          isLoading={this.state.isLoading}
          success={this.state.fetchSuccess}
          message={this.state.fetchMsg}
        />
        <div id="pick-list-title">
          <span id="pick-list-description">
            This pick list contains parts from the following part lists:{" "}
          </span>
          <span id="pick-lists">
            {Object.keys(this.state.partLists).map((partListKey, i) => (
              <Link to={`/partlist/show/${partListKey}`} key={partListKey}>
                <span key={partListKey}>
                  {this.state.partLists[partListKey]}
                </span>
                {i < Object.keys(this.state.partLists).length - 1 ? ", " : null}
              </Link>
            ))}
          </span>
        </div>
        <div id="pick-list-parts">
          <div id="pick-list-columns">
            <div className="pick-list-column">Number</div>
            <div className="pick-list-column">Rev</div>
            <div className="pick-list-column">Description</div>
            <div className="pick-list-column numeric">Qty. Req.</div>
            <div className="pick-list-column numeric">Qty. Kitted</div>
            <div className="pick-list-column numeric">Qty. to Kit</div>
          </div>
          {this.state.reducedRequiredParts.map((reducedRequiredPart) => (
            <PickListRow
              reducedRequiredPart={reducedRequiredPart}
              onSetToKitQuantity={this.handleSetToKitQuantity}
              key={reducedRequiredPart.part.id}
            />
          ))}
        </div>
        <button id="save-pick-list" onClick={this.patchReducedRequiredParts}>
          Save
        </button>
      </div>
    );
  };

  handleSetToKitQuantity = (partId, value) => {
    this.setState({
      reducedRequiredParts: this.state.reducedRequiredParts.map(
        (reducedRequiredPart) => {
          if (reducedRequiredPart.part.id === partId)
            return {
              ...reducedRequiredPart,
              toKitQuantity: value,
            };

          return reducedRequiredPart;
        }
      ),
    });
  };

  getReducedRequiredParts = async (id) => {
    let response = await this.apiQuery(
      `${baseURI}/shipment/${id}/reducedrequiredparts`,
      "GET",
      null,
      `Successfully collated all parts from all partlists on shipment ${this.props.match.params.id}`,
      `Failed to fetch collated parts from all partlists on shipment ${this.props.match.params.id}`
    );
    if (!response.ok) return null;
    let reducedRequiredParts = await response.json();

    return reducedRequiredParts.map(mapReducedRequiredPart);
  };

  patchReducedRequiredParts = async () => {
    let partsToUpdate = this.state.reducedRequiredParts.filter(
      (reducedRequiredPart) => Number(reducedRequiredPart.toKitQuantity)
    );
    if (partsToUpdate.length === 0) return;

    await this.apiQuery(
      `${baseURI}/shipment/${this.props.match.params.id}/requiredparts`,
      "PATCH",
      JSON.stringify(partsToUpdate.map(requiredPartToObject)),
      `Successfully kitted additional part quantites for ${partsToUpdate.length} parts`,
      "Failed to update parts."
    );

    let response = await this.getReducedRequiredParts(
      this.props.match.params.id
    );

    if (!response) return;
    this.setReducedRequiredPartsState(response);
  };
}

const requiredPartToObject = (reducedRequiredPart) => ({
  part_id: reducedRequiredPart.part.id,
  kitted_qty: reducedRequiredPart.toKitQuantity,
});

const mapReducedRequiredPart = (requiredPart) => ({
  totalKittedQuantity:
    requiredPart.totalQtyKitted && Number(requiredPart.totalQtyKitted),
  totalRequiredQuantity:
    requiredPart.totalQtyRequired && Number(requiredPart.totalQtyRequired),
  part: requiredPart.part && mapPart(requiredPart.part),
  requiredParts:
    requiredPart.requiredParts &&
    requiredPart.requiredParts.map(mapRequiredPart),
  partLists: requiredPart.shipLists && mapPartLists(requiredPart.shipLists),
  toKitQuantity: "",
});

const mapPartLists = (partLists) => partLists;

const mapPart = (part) => ({
  UMVerified: part.UMVerified,
  costEach: (part.costEach && Number(part.costEach)) || null,
  countryOfOrigin: part.countryOfOrigin,
  created_at: (part.created_at && new Date(part.created_at)) || null,
  description: part.description,
  extendedDescription: part.extendedDesciption,
  id: part.id && Number(part.id),
  material: part.material,
  obsolete: (part.obsolete && Boolean(part.obsolete)) || null,
  revision: part.revision,
  unitOfMeasure: part.unitOfMeasure,
  updated_at: (part.updatedAt && new Date(part.updatedAt)) || null,
  usedFor: part.usedFor,
  weight: (part.weight && Number(part.weight)) || null,
  number: part.number,
});

const mapRequiredPart = (requiredPart) => ({
  createdAt:
    (requiredPart.created_at && new Date(requiredPart.created_at)) || null,
  updatedAt:
    (requiredPart.updated_at && new Date(requiredPart.updated_at)) || null,
  id: requiredPart.id && Number(requiredPart.id),
  kittedQuantity: requiredPart.kitted_qty && Number(requiredPart.kitted_qty),
  perBagQuantity: requiredPart.qty_per_bag && Number(requiredPart.qty_per_bag),
  requiredQuantity: requiredPart.req_qty && Number(requiredPart.req_qty),
  partListId: requiredPart.shiplist_id && Number(requiredPart.shiplist_id),
});
