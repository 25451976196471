/*
 * Set the loading indicator's state for fetch requests
 * @param { Response } response - Server response
 * @param { string } successMsg - Message to display on successful load
 * @param { string } failureMsg - Message to display on failure to load
 */
function setLoadingStatus(
  response,
  successMsg = "Successfully updated!",
  failureMsg = ``
) {
  if (!response) {
    this.setState({
      isLoading: true,
      fetchSuccess: null,
      fetchMsg: "loading",
    });
  } else {
    this.setState({
      isLoading: false,
      fetchSuccess: response.ok || response.ok == undefined,
      fetchMsg:
        response.ok || response.ok == undefined ? successMsg : failureMsg,
    });
  }
}

export default setLoadingStatus;
