import React, { Component } from "react";
import { Link } from "react-router-dom";

import PartListRow from "../presentational/PartListRow";
import SubmitBtn from "../presentational/SubmitBtn";
import Status from "../presentational/Status";
import setLoadingStatus from "../../helpers/loading-status";
import Pagination from "../presentational/Pagination";

import { baseURI } from "../../config/host-info";
import apiQuery from "../../helpers/api-query";

/*
 * The ship list index allows users to search for shipping lists
 */
class PartListIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partLists: [],
      userFilter: "",
      isLoading: false,
      fetchSuccess: null,
      fetchMsg: "",
      pagInputVal: 0,
      currentPage: 0,
      numPages: null,
      resultsPerPage: null,
    };

    this.setLoadingStatus = setLoadingStatus.bind(this);
    this.apiQuery = apiQuery.bind(this);
  }

  /*
   * Pull a list of all shipments from the API when we visit the ship list index
   */
  componentDidMount = () => {
    // Pre-populate the data for the user
    let currentPage = Number(window.location.hash.split(/page=/)[1]) || 0;
    this.setState({ currentPage: currentPage }, () => {
      this.getPartLists();
    });
    this.props.history.push(`/partlist#page=${currentPage}`);

    // User presses the back button
    window.onpopstate = () => {
      this.getPartLists();
    };
  };

  /* Get partlist indices */
  getPartLists = async () => {
    let response = await this.apiQuery(
      `${baseURI}/partlist?offset=${
        this.state.currentPage * this.state.resultsPerPage
      }`,
      "GET",
      null,
      `Successfully retrieved partlists`,
      `Failed to retrieve partlists`
    );
    let json = response.ok ? await response.json() : [];
    this.setState({
      partLists: json,
      numPages: Math.ceil(
        response.headers.get("X-Total-Record-Count") /
          response.headers.get("X-Count-Size")
      ),
      resultsPerPage: Number(response.headers.get("X-Count-Size")),
    });
  };

  /*
   * Set the user entered pagination page
   * @param {string | number} value - User entered page
   * @param {boolean} clicked - Whether the user clicked on a pagination page or not
   */
  setPage = (value, clicked) => {
    this.setState(
      {
        pagInputVal: value,
        currentPage: Math.min(Math.max(Number(value), 0), this.state.numPages),
      },
      () => {
        if (clicked) {
          this.props.history.push(`/partlist#page=${this.state.currentPage}`);
          this.getPartLists();
        }
      }
    );
  };

  /*
   * Load data from the newly selected pagination page
   */
  loadPage = () => {
    this.getShipments();
  };

  /*
   * User edits the filter determining which parts to show
   * @param { string } value - User input string to match parts/ids to
   */
  editFilter = (value) => {
    this.setState({ userFilter: value });
  };

  /*
   * Create components for each PartList row
   */
  populatePartListRows = () => {
    return this.state.partLists.map((partList) => {
      if (
        this.state.userFilter === "" ||
        String(partList["id"]).indexOf(this.state.userFilter) !== -1 ||
        partList["jos"]
          .toLowerCase()
          .indexOf(this.state.userFilter.toLocaleLowerCase()) !== -1
      ) {
        return (
          <PartListRow
            type="default"
            partListNumber={partList["id"]}
            jobOrders={partList["jos"]}
            key={partList["id"]}
            id={partList["id"]}
            shipmentNumber={partList["shipment_id"]}
          />
        );
      } else {
        return null;
      }
    });
  };

  render = () => {
    return (
      <div className="ship-list-index">
        <Status
          isLoading={this.state.isLoading}
          success={this.state.fetchSuccess}
          message={this.state.fetchMsg}
        />
        <label className="shipment-title">PartLists</label>
        <div className="filter-row">
          <input
            className="filter-ship-list"
            placeholder="Filter ship lists..."
            onChange={(e) => {
              this.editFilter(e.target.value);
            }}
            value={this.state.userFilter}
          />
          <Link to="/partlist/create">
            <SubmitBtn type="create">Create</SubmitBtn>
          </Link>
        </div>
        <div className="partlists">
          <div className="partlist-labels">
            <div id="index">Index</div>
            <div id="jos">Job Orders</div>
            <div id="shipment">Associated Shipment</div>
          </div>
          {this.populatePartListRows()}
        </div>
      </div>
    );
  };
}

export default PartListIndex;
