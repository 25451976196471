/*
 * Deep copy an object or array
 *
 * @param { Object|Array } obj: Object to copy
 * @return { Object|Array } deeply copied object
 */
const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export default deepCopy;
