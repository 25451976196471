import React, { PureComponent } from "react";

/*
 * Displays the part codes in the Part Show component
 */
class Codes extends PureComponent {
  /*
   * Validate the format for TARIC, GHS, and Schedule B
   * @param {string} code - Code category (ghs, scheduleb, ...)
   * @param {string|number} value - User entered code number
   * @return {boolean} Code number is valid
   */
  validateCode = (code, value) => {
    if (value === "") {
      return true;
    }
    switch (code) {
      case "scheduleB":
        return /^\d{4}\.\d{2}\.\d{4}$/.test(value);
      case "ghs":
        return /^\d{4}\.\d{2}$/.test(value);
      case "TARIC":
        return /^\d{10}$/.test(value);
      default:
        throw new Error(`Invalid code category ${code}.`);
    }
  };

  render = () => {
    return (
      <div className="codes">
        <div className="code-title">Codes</div>
        <label>Globally Harmonized System Code</label>
        <input
          className={`system-code ${
            this.validateCode("ghs", this.props.ghs) ? "valid" : "invalid"
          }`}
          value={this.props.ghs}
          onChange={(e) => {
            this.props.editCodes(e.target.value, "ghs");
          }}
        />
        <label>Schedule B Code</label>
        <input
          className={`schedule-b-code ${
            this.validateCode("scheduleB", this.props.scheduleB)
              ? "valid"
              : "invalid"
          }`}
          value={this.props.scheduleB}
          onChange={(e) => {
            this.props.editCodes(e.target.value, "scheduleB");
          }}
        />
        <label>TARIC Code</label>
        <input
          className={`taric-code ${
            this.validateCode("TARIC", this.props.TARIC) ? "valid" : "invalid"
          }`}
          value={this.props.TARIC}
          onChange={(e) => {
            this.props.editCodes(e.target.value, "TARIC");
          }}
        />
      </div>
    );
  };
}

export default Codes;
