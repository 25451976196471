import React, { Component } from "react";

import { baseURI } from "../../config/host-info";
import apiQuery from "../../helpers/api-query";
import setLoadingStatus from "../../helpers/loading-status";

import SubmitBtn from "../presentational/SubmitBtn";

/*
 * The ship list create allows the creation of new ship lists
 */
class PartListCreate extends Component {
  constructor(props) {
    super(props);

    this.state = { jos: "" };

    this.apiQuery = apiQuery.bind(this);
    this.setLoadingStatus = setLoadingStatus.bind(this);
  }

  /*
   * Set the loading indicator's state
   * @param { Response } response - Server response
   */
  setLoadingStatus = (response) => {
    if (!response) {
      this.setState({
        isLoading: true,
        fetchSuccess: null,
        fetchMsg: "loading",
      });
    } else {
      this.setState({
        isLoading: false,
        fetchSuccess: response.ok,
        fetchMsg: response.ok
          ? "Successfully updated!"
          : `Failed. Server responded with code ${response.status}.`,
      });
    }
  };

  /*
   * User edits the jos field
   * @param { string } value - User entered text
   */
  editOrders = (value) => {
    this.setState({ jos: value });
  };

  /*
   * User clicks create partlist
   */
  createPartList = async () => {
    let endpoint = `${baseURI}/partlist`;
    let body = { jos: this.state.jos };

    // Check for an shipment association
    let shipment_id = window.location.href.split("#")[1];
    if (shipment_id) {
      body["shipment_id"] = Number(shipment_id);
    }

    let res = await this.apiQuery(
      endpoint,
      "POST",
      JSON.stringify(body),
      ``,
      `Failed to create a partlist.`
    );

    if (res.ok) {
      let json = await res.json();
      this.props.history.push(`/partlist/show/${json.id}`);
    }
  };

  render = () => {
    return (
      <div className="ship-list-create">
        <label className="create-ship-list-title">Create PartList</label>
        <label className="orders-title">Job Orders</label>
        <input
          className="parts-input"
          onChange={(e) => {
            this.editOrders(e.target.value);
          }}
        />
        <SubmitBtn
          type="create"
          onClick={() => {
            this.createPartList();
          }}
        >
          Create
        </SubmitBtn>
      </div>
    );
  };
}

export default PartListCreate;
