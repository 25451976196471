import React, { Component } from "react";
import { Link } from "react-router-dom";

/*
 * Landing component which describes the different pages to the user
 */
class Landing extends Component {
  render = () => {
    return (
      <div className="landing-container">
        <div className="intro">
          Welcome to ShipCoordinator! Use the navigation bar above, or the three
          major website sections described below, to find where you need to go.
        </div>
        <div className="landing">
          <Link to="/shipment">
            <div className="shipment-description landing-card">
              <div className="title">Shipment</div>
              <div className="description">
                <ul>
                  <li>See a list of all shipments</li>
                  <li>See or modify details of a specific shipment</li>
                  <li>Create a new shipment</li>
                </ul>
              </div>
            </div>
          </Link>
          <Link to="/partlist">
            <div className="partlist-description landing-card">
              <div className="title">PartList</div>
              <div className="description">
                <ul>
                  <li>See a list of all part lists</li>
                  <li>See or modify details of a specific part list</li>
                  <li>Create a new part list</li>
                </ul>
              </div>
            </div>
          </Link>
          <Link to="/part">
            <div className="part-description landing-card">
              <div className="title">Part</div>
              <div className="description">
                <ul>
                  <li>Find a part by its part number</li>
                  <li>Modify or add translations for a part</li>
                  <li>Modify or add codes for a part</li>
                </ul>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  };
}

export default Landing;
