import React, { PureComponent } from "react";

/*
 * Part row containing kitted part information
 */
class KittedPartRow extends PureComponent {
  viewPart = () =>
    window.open(`/part#p=${this.props.partNumber}r=${this.props.revision}`);

  render = () => {
    // Part will only display itself if some are kitted, but kitted exceeds the packed qty
    if (
      this.props.kittedQty > 0 &&
      this.props.totalPacked < this.props.kittedQty
    ) {
      return (
        <div className="kitted-part-row">
          <div className="info">
            <div className="part-number" onClick={this.viewPart}>
              {this.props.partNumber || "?"}
            </div>
            <div className="revision" onClick={this.viewPart}>
              {this.props.revision || "?"}
            </div>
            <div className="description" onClick={this.viewPart}>
              {this.props.description || "?"}
            </div>
          </div>
          <div className="update-qty">
            <div className="kitted-qty">
              {this.props.kittedQty % 1 == 0 && this.props.unitOfMeasure == "EA"
                ? Math.trunc(this.props.kittedQty)
                : this.props.kittedQty}
            </div>
            <input
              className="pack-qty"
              onChange={(e) => {
                this.props.onChange(this.props.id, e.target.value);
              }}
              value={this.props.toPack || ""}
            />
            <input
              className="qty-per-bag"
              onChange={(e) => {
                this.props.editQtyPerBag(this.props.id, e.target.value);
              }}
              value={this.props.qtyPerBag || ""}
            />
            <div className="remaining-qty">
              {(
                Number(this.props.kittedQty) -
                (Number(this.props.toPack) + Number(this.props.totalPacked))
              ).toFixed(2)}
            </div>
            <label className="unit-of-measure">
              {this.props.unitOfMeasure}
            </label>
            <input
              className="weight-each"
              onChange={(e) => {
                this.props.setWeightEach(this.props.id, e.target.value);
              }}
              value={this.props.userWeightEach || ""}
              disabled={!this.props.toPack}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };
}

export default KittedPartRow;
