import React, { PureComponent } from "react";

/*
 * User pagnination navigation component
 */
class Pagination extends PureComponent {
  /* Create the elements between the min/max elements */
  populateBetween = () => {
    let firstEntry = Math.max(
      0,
      this.props.currentPage - Math.trunc(this.props.pageSpread / 2)
    );
    let lastEntry = firstEntry + this.props.pageSpread;

    if (lastEntry > this.props.numPages) {
      lastEntry = this.props.numPages;
      firstEntry = Math.max(0, this.props.currentPage - this.props.pageSpread);
    }

    let pages = [];
    for (let i = firstEntry; i < lastEntry; i++) {
      pages.push(
        <div
          className={`page${i === this.props.currentPage ? " active" : ""}`}
          onClick={() => {
            this.props.setPage(i, true);
          }}
          key={i}
        >
          {i}
        </div>
      );
    }

    return pages;
  };

  render = () => {
    return (
      <div className="pagination">
        <div className="pages">
          {this.props.currentPage !== 0 ? (
            <div
              className="page beginning"
              onClick={() => {
                this.props.setPage(0, true);
              }}
            >
              «
            </div>
          ) : null}
          {this.populateBetween()}
          {this.props.currentPage + 1 !== this.props.numPages ? (
            <div
              className="page end"
              onClick={() => {
                this.props.setPage(this.props.numPages - 1, true);
              }}
            >
              »
            </div>
          ) : null}
        </div>
        <div className="enter-page">
          <input
            onChange={(e) => {
              this.props.setPage(e.target.value, false);
            }}
            onKeyPress={(e) => {
              if (e.key == "Enter") {
                this.props.setPage(e.target.value, true);
              }
            }}
            value={this.props.pagInputVal}
          />
        </div>
      </div>
    );
  };
}

export default Pagination;
