import React, { PureComponent } from "react";

/*
 *
 */
class PackedPartRow extends PureComponent {
  viewPart = () =>
    window.open(`/part#p=${this.props.partNumber}r=${this.props.revision}`);

  render = () => {
    return (
      <div className="packed-part-row">
        <div className="info">
          <div className="item-number" onClick={this.viewPart}>
            {this.props.itemNumber || "?"}
          </div>
          <div className="part-number" onClick={this.viewPart}>
            {this.props.partNumber || "?"}
          </div>
          <div className="revision" onClick={this.viewPart}>
            {this.props.revision || "?"}
          </div>
          <div className="description" onClick={this.viewPart}>
            {this.props.description || "?"}
          </div>
        </div>
        <div className="update-qty">
          <div className="pallet">{this.props.pallet || "?"}</div>
          <div className="box">{this.props.box || "?"}</div>
          <div className="qty">
            {this.props.qty % 1 == 0 && this.props.unitOfMeasure == "EA"
              ? Math.trunc(this.props.qty)
              : Number(this.props.qty).toFixed(2)}
          </div>
          <input
            className="unpack-qty"
            onChange={(e) => {
              this.props.onChange(this.props.id, e.target.value);
            }}
            value={this.props.userToUnpack || ""}
          />
          <label className="unit-of-measure">{this.props.unitOfMeasure}</label>
        </div>
      </div>
    );
  };
}

export default PackedPartRow;
