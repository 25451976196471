import React, { Component } from "react";
import { Route } from "react-router-dom";

import PartListIndex from "./PartListIndex";
import PartListShow from "./PartListShow";
import PartListCreate from "./PartListCreate";
import PartListLabels from "./PartListLabels";

/*
 * The ship list desktop component allows users to
 */
class PartList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <div className="ship-list">
        <Route exact path="/partlist" component={PartListIndex} />
        <Route exact path="/partlist/show/:id" component={PartListShow} />
        <Route exact path="/partlist/create" component={PartListCreate} />
        <Route exact path="/partlist/labels/:id" component={PartListLabels} />
      </div>
    );
  };
}

export default PartList;
