import React, { PureComponent } from "react";

import Dropdown from "./Dropdown";

/*
 * Part row with user inputs to create a new part row
 */
class AddPartRow extends PureComponent {
  render = () => {
    return (
      <div className="add-part-row">
        <div className="info">
          <input
            className="part-number"
            value={this.props.partNumber || ""}
            onChange={(e) => {
              // Set the part number, find its revisions, and its part info
              this.props.setPartNum(this.props.i, e.target.value);
            }}
          />
          <Dropdown
            type="revision"
            options={this.props.revisions
              .filter((revision) => !revision.obsolete)
              .map((revision) => revision.revision)}
            onChange={(e) => {
              this.props.onChange(this.props.i, e.target.value, true);
            }}
          />
          <div className="description">{this.props.description}</div>
        </div>
        <input
          className="update-qty"
          value={this.props.updateQty || ""}
          onChange={(e) => {
            this.props.editRequiredQty(this.props.i, e.target.value, true);
          }}
        />
      </div>
    );
  };
}

export default AddPartRow;
