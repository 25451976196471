import React from "react";
import { Link } from "react-router-dom";
import "./PickListRow.css";

export default class PickListRow extends React.Component {
  onChange = (e) =>
    this.props.onSetToKitQuantity(
      this.props.reducedRequiredPart.part.id,
      e.target.value
    );

  render = () => {
    const { reducedRequiredPart } = this.props;
    return (
      <div className="pick-list-row">
        <div className="number">
          <Link
            to={`/part#p=${reducedRequiredPart.part.number}r=${reducedRequiredPart.part.revision}`}
            tabIndex={"-1"}
          >
            {reducedRequiredPart.part.number}
          </Link>
        </div>
        <div className="revision">
          <Link
            to={`/part#p=${reducedRequiredPart.part.number}r=${reducedRequiredPart.part.revision}`}
            tabIndex={"-1"}
          >
            {reducedRequiredPart.part.revision}
          </Link>
        </div>
        <div className="description">
          <Link
            to={`/part#p=${reducedRequiredPart.part.number}r=${reducedRequiredPart.part.revision}`}
            tabIndex={"-1"}
          >
            {reducedRequiredPart.part.description}
          </Link>
        </div>
        <div className="totalRequiredQuantity">
          {reducedRequiredPart.totalRequiredQuantity}
        </div>
        <div className="totalKittedQuantity">
          {Number(reducedRequiredPart.totalKittedQuantity) +
            Number(reducedRequiredPart.toKitQuantity)}
        </div>
        <input
          type="number"
          className="toKitQuantity"
          value={reducedRequiredPart.toKitQuantity}
          onChange={this.onChange}
        />
      </div>
    );
  };
}
