import React, { Component } from "react";

import { baseURI } from "../../config/host-info";
import setLoadingStatus from "../../helpers/loading-status";
import apiQuery from "../../helpers/api-query";
import QueryM2mApi from "../../helpers/m2m-api";
import isValidPartNumber from "../../helpers/valid-part";
import deepCopy from "../../helpers/deep-copy";

import SubmitBtn from "../presentational/SubmitBtn";
import RequiredPartRow from "../presentational/RequiredPartRow";
import AddPartRow from "../presentational/AddPartRow";
import Status from "../presentational/Status";
import FilterDropdown from "../presentational/FilterDropdown";
import { setSortFilter, Labels } from "../presentational/Labels";
import PhantomFrame from "../presentational/PhantomFrame";
import KittedPartRow from "../presentational/KittedPartRow";
import DeletedPartRow from "../presentational/DeletedPartRow";
import AuditLog from "./AuditLog/AuditLog";
import { getAuthHeader } from "../../helpers/auth";

/*
 * The ship list create allows the creation of new ship lists
 */
class PartListShow extends Component {
  constructor(props) {
    super(props);

    this.newRequiredPartTemplate = {
      part: {
        number: null,
        revision: null,
        id: null,
        req_qty: null,
      },
    };

    this.state = {
      id: null,
      isLoading: false,
      fetchSuccess: null,
      fetchMsg: "",
      shipments: [],
      jos: "",
      requiredParts: [],
      newRequiredParts: [deepCopy(this.newRequiredPartTemplate)],
      selectedShipment: -1,
      associatedShipment: -1,
      languages: [],
      updateInProgress: false,
      sort: null,
      sortDescending: true,
      phantomWPN: "",
      phantomParts: [],
      deletedParts: [],
      turboTranslations: false,
    };

    this.setLoadingStatus = setLoadingStatus.bind(this);
    this.apiQuery = apiQuery.bind(this);
    this.setSortFilter = setSortFilter.bind(this);
    this.QueryM2mApi = QueryM2mApi.bind(this);
  }

  /* We need partlist information upon loading this component */
  componentDidMount = () => {
    this.getBasePartListInfo();
    this.getRequiredParts();
    this.getShipments();
    this.getLanguages();
    this.getDeletedParts();
  };

  /* Get basic PartList information, e.g. JOs */
  getBasePartListInfo = async () => {
    let res = await this.apiQuery(
      `${baseURI}/partlist/${this.props.match.params.id}`,
      "GET",
      null,
      "",
      ""
    );
    let payload = [];
    if (res.ok) {
      payload = await res.json();
    }
    this.setState({
      jos: payload.jos,
      shipments: payload.shipment || [],
      id: payload.id,
      associatedShipment: payload["shipment_id"] || -1,
      selectedShipment: payload["shipment_id"] || -1,
      modified: payload["modified"],
    });
  };

  /*
   * Get shipments (which we can associate with a partlist)
   */
  getShipments = async () => {
    let res = await this.apiQuery(`${baseURI}/shipment`, "GET", null, "", "");
    let shipments = await res.json();
    this.setState({ shipments: shipments });
  };

  /* Get parts required for this partlist */
  getRequiredParts = async () => {
    let res = await this.apiQuery(
      `${baseURI}/partlist/${this.props.match.params.id}/requiredpart`,
      "GET",
      null,
      `Successfully found required parts for partlist ${this.props.match.params.id}`,
      `Failed to find required parts for partlist ${this.props.match.params.id}`
    );
    res = await res.json();
    res.map((part) => {
      Object.assign(part, {
        originalQty: part.req_qty,
        originalWeight: part.part.weight,
      });
    });

    this.setState(
      {
        requiredParts: res,
      },
      async () => {
        for (let part of this.state.requiredParts) {
          const fetchFn = this.getTranslations;
          if (this.state.turboTranslations) {
            fetchFn(part.id);
          } else {
            await fetchFn(part.id);
          }
        }
      }
    );
  };

  /* Get deleted required parts */
  getDeletedParts = async () => {
    let res = await this.apiQuery(
      `${baseURI}/partlist/${this.props.match.params.id}/requiredpart/deleted`,
      "GET",
      null,
      `Successfully found deleted required parts for partlist ${this.props.match.params.id}`,
      `Failed to find deleted required parts for partlist ${this.props.match.params.id}`
    );
    res = await res.json();
    this.setState({ deletedParts: res });
  };

  /*
   * Get a list of available languages for translation
   */
  getLanguages = async () => {
    let response = await this.apiQuery(
      `${baseURI}/language`,
      "GET",
      null,
      "Successfully found a list of languages from the server.",
      "Could not get a list of languages from the server."
    );
    let json = response.ok ? await response.json() : [];

    this.setState({ languages: json });
  };

  /*
   * Fetch a required part's revisions as the user types
   * @param { string } i - index of which add parts row we're modifying
   * @param { string } partNumber - Part number the user enters
   * @param { boolean } newPart - Is this a lookup for current required parts, or new ones?
   */
  getPartRevisions = async (i, partNumber, newPart = false) => {
    /* Which type of part are we trying to get revisions for? New or current? */
    let parts = newPart
      ? this.state.newRequiredParts
      : this.state.requiredParts;
    let res = await this.apiQuery(
      `${baseURI}/${parts[i].part.number}/rev`,
      "GET",
      null,
      ``,
      ``
    );
    let json = res.ok ? await res.json() : [];
    json.sort((a, b) => Date.parse(a.created_at) < Date.parse(b.created_at));

    /* Filter out obsolete revisions */
    json = json.filter((rev) => rev.obsolete == 0);

    // Time to update the immutable state tree
    let newRequiredParts = Object.assign([], parts);

    // Only update the required part if the user-typed part number matches
    // the response we eventually receive here. NO STALE DATA FOR US!
    if (parts[i] && parts[i].part && parts[i].part.number === partNumber) {
      newRequiredParts[i].revisions = json;
      if (json.length !== 0) {
        newRequiredParts[i].focusedRevision = json[0].revision;
      } else {
        newRequiredParts[i].focusedRevision = null;
      }

      parts
        ? this.setState({ newRequiredParts: newRequiredParts })
        : this.setState({ requiredParts: newRequiredParts });
    }

    return json;
  };

  getTranslations = async (id) => {
    let partInfo = this.state.requiredParts.find((p) => p.id === id).part;

    // Empty revision endpoint differs from the non-empty revision endpoint
    let uri =
      partInfo.revision === ""
        ? "/translation"
        : `/${partInfo.revision}/translation`;

    // We'll be finding translations for each part on the page, so let's suppress
    // loading status messages. That could get messy.
    let res = await this.apiQuery(
      `${baseURI}/${partInfo.number}${uri}`,
      "GET",
      null,
      "",
      ""
    );

    // We still need to re-find the part in state, since the user could've
    // deleted it, added new parts, etc since we sent the api request.
    let translations = res.ok ? await res.json() : null;
    if (translations === null) {
      return;
    }

    let requiredParts = Object.assign([], this.state.requiredParts);
    requiredParts.find((p) => p.id === id).translations = translations;
    this.setState({ requiredParts });
  };

  /*
   * PATCH the job orders to match those entered by the user in the
   * job orders form.
   */
  patchJobOrders = async () => {
    let endpoint = `${baseURI}/partlist/${this.state.id}`;
    let body = JSON.stringify({ jos: this.state.jos });
    await this.apiQuery(
      endpoint,
      "PATCH",
      body,
      "Successfully patched the job orders!",
      "Failed to patch the job orders"
    );
  };

  /*
   * If the shipment has already been tainted, let's warn the user
   * @param {string} id - Shipment ID
   */
  confirmPartListAssoc = (id) => {
    let newShipment = this.state.shipments.find((s) => s.id == id);
    if (id === -1) {
      return true;
    }

    if (newShipment && newShipment.modified) {
      let confirmed = window.confirm(
        `The selected shipment has already started the packing process.\
        Are you SURE you want to continue?`
      );
      return confirmed;
    }

    return true;
  };

  updateAssociatedShipment = async () => {
    let endpoint = `${baseURI}/partlist/${this.props.match.params.id}/shipment/${this.state.selectedShipment}`;
    let successMsg =
      "Successfully associated this partlist with the chosen shipment!";
    if (this.state.selectedShipment === -1) {
      endpoint = `${baseURI}/partlist/${this.props.match.params.id}/shipment`;
      successMsg = `Successfully disassociated this partlist from shipment ${this.state.associatedShipment}`;
    }
    let method = this.state.selectedShipment == -1 ? "DELETE" : "POST";
    let res = await this.apiQuery(
      endpoint,
      method,
      null,
      successMsg,
      "Failed to handle the partlist/shipment association."
    );

    let seletedShipment = res.ok
      ? this.state.selectedShipment
      : this.state.associatedShipment;
    this.setState({
      associatedShipment: seletedShipment,
      selectedShipment: seletedShipment,
    });
  };

  /*
   * POST/PATCH/DELETE the required part row at the given index
   * @param { number } i - list index of which part we are updating
   * @param { number } method - Are we POSTing, PATCHing, or DELETEing this part?
   * @param { boolean } newPart - Is this a new part we're adding?
   */
  updateRequiredPart = async (i, method, newPart = false) => {
    let part = newPart
      ? this.state.newRequiredParts[i]
      : this.state.requiredParts[i];
    let endpoint = `${baseURI}`;
    let body = {};
    switch (method) {
      case "POST":
        endpoint += `/requiredpart`;
        body = {
          part_id: part.focusedRevision ? undefined : part.part.id,
          req_qty: part.req_qty,
          kitted_qty: 0,
          shiplist_id: this.state.id,
          number: part.part.number,
          revision: part.focusedRevision || part.part.revision,
        };
        break;
      case "PATCH":
        endpoint += `/requiredpart/${this.state.requiredParts[i].id}`;
        body = {
          req_qty: part.req_qty,
          weight: part.part.weight,
        };
        break;
      case "DELETE":
        endpoint += `/requiredpart/${this.state.requiredParts[i].id}`;
        break;
      default:
        throw new Error("Specified method must be POST, PATCH, or DELETE!");
    }

    let res = await this.apiQuery(
      endpoint,
      method,
      JSON.stringify(body),
      `Successfully updated required part ${part.part.number}`,
      `Failed update required part ${part.part.number}`
    );

    if (res.ok && !newPart) {
      let parts = [...this.state.requiredParts];
      parts[i].originalQty = part.req_qty;
      this.setState({ reqiredParts: parts });
    }
  };

  /*
   * Updates ALL required parts in the state. POSTing, PATCHing, and DELETEing
   * each part as required
   */
  updateRequiredParts = async () => {
    // Don't let the user POST many times
    this.setState({
      updateInProgress: true,
    });

    let requiredPartUpdates = [];
    for (let i = 0; i < this.state.requiredParts.length; i++) {
      let part = this.state.requiredParts[i];
      if (part.req_qty === 0) {
        requiredPartUpdates.push(this.updateRequiredPart(i, "DELETE"));
      } else if (
        (part.req_qty && part.req_qty !== part.originalQty) ||
        part.part.weight != part.originalWeight
      ) {
        requiredPartUpdates.push(this.updateRequiredPart(i, "PATCH"));
      }
    }

    let newRequiredPartUpdates = [];
    for (let i = 0; i < this.state.newRequiredParts.length; i++) {
      let part = this.state.newRequiredParts[i];
      if (part.req_qty && part.part.number) {
        newRequiredPartUpdates.push(this.updateRequiredPart(i, "POST", true));
      }
    }

    await Promise.all(requiredPartUpdates);
    await Promise.all(newRequiredPartUpdates);

    this.setState(
      {
        newRequiredParts: [deepCopy(this.newRequiredPartTemplate)],
        updateInProgress: false,
      },
      this.getRequiredParts
    );
  };

  /*
   * User selects a different shipment from the dropdown list,
   * prompting an API call
   * @param { number } value -shipment which the user selects
   */
  selectShipment = (value) => {
    if (this.confirmPartListAssoc(value)) {
      this.setState({ selectedShipment: value });
    }
  };

  /*
   * Update the JOs
   * @param value - User inputted value
   */
  setJos = (value) => {
    this.setState({ jos: value });
  };

  filterParts = (filter) => {
    this.setState({ sortOrder: filter });
  };

  /*
   * Update the given part number, and fetch details of the given part
   * @param { number } i - Index of new part which is changing
   * @param { string } value - User entered value of the part number
   */
  setPartNum = async (i, value) => {
    let newRequiredParts = Object.assign([], this.state.newRequiredParts);
    newRequiredParts[i].part = { number: value };

    // Before refreshing the UI, remove blank new required part rows
    newRequiredParts = [
      ...newRequiredParts.filter((row) => row.part.number),
      deepCopy(this.newRequiredPartTemplate),
    ];
    this.setState({ newRequiredParts }, async () => {
      // Don't worry about looking up a part if it's in an invalid format
      if (!isValidPartNumber(value)) {
        return;
      }

      let res = await this.getPartRevisions(i, value, true);
      if (res.length !== 0) {
        this.getPartInfo(i, true);
      }
    });
  };

  /*
   * Find part information for the given part
   * @param { number } i - Which part in the list of parts?
   * @param { boolean } newPart - Is this a new part or an already req. one?
   */
  getPartInfo = async (i, newPart = false) => {
    let parts = newPart
      ? this.state.newRequiredParts
      : this.state.requiredParts;
    let part = parts[i];
    if (!part || !part.part) {
      return;
    }
    let partNumber = part.part.number;

    let revisionUrl = "";
    if (part.focusedRevision !== "") {
      revisionUrl = `/${part.focusedRevision}`;
    }
    let res = await this.apiQuery(
      `${baseURI}/${part.part.number}${revisionUrl}`,
      "GET",
      null,
      "",
      ""
    );
    let json = res.ok ? await res.json() : null;
    let newRequiredParts = Object.assign([], parts);

    // Avoid race condition on quickly entered part numbers
    parts = newPart ? this.state.newRequiredParts : this.state.requiredParts;
    if (
      json &&
      json.number &&
      parts[i].part &&
      json.number == parts[i].part.number
    ) {
      newRequiredParts[i].part = json;

      this.setState(
        newPart
          ? { newRequiredParts: newRequiredParts }
          : { requiredParts: newRequiredParts }
      );
    }
  };

  /*
   * Update required quantity for a required part
   * @param i- Which required part to update
   * @param value - User inputted value
   */
  updateRequiredQty = (i, value) => {
    let newRequiredParts = Object.assign([], this.state.requiredParts);
    let part = newRequiredParts[i];
    part.req_qty = value;

    this.setState({ requiredParts: newRequiredParts });
  };

  /**
   * Update required part weight
   * @param {number} i - Index of required part to update
   * @param {string} value - User input value
   */
  updateWeight = (i, value) => {
    let requiredParts = [...this.state.requiredParts];
    requiredParts[i].part.weight = value;
    this.setState({ requiredParts });
  };

  /*
   * Set part revision to user selected dropdown value
   * @param id - Which required part to update
   * @param value - User inputted value
   * @param newPart - Are we updating revisions for a new part, or current one?
   */
  selectRevision = (id, value, newPart = false) => {
    let parts = newPart
      ? this.state.newRequiredParts
      : this.state.requiredParts;

    let newRequiredParts = Object.assign([], parts);
    let part = parts[id];
    if (part) part.focusedRevision = value;
    this.setState(
      newPart
        ? { newRequiredParts: newRequiredParts }
        : { requiredParts: newRequiredParts },
      () => {
        this.getPartInfo(id, newPart);
      }
    );
  };

  /*
   * Edit the required quantity within an add part row
   * @param i - Which new required part to update
   * @param value - User inputted required quantity
   * @param newPart - Editing a new part, or an existing one?
   */
  editRequiredQty = (i, value, newPart) => {
    let parts = newPart
      ? this.state.newRequiredParts
      : this.state.requiredParts;
    let newRequiredParts = Object.assign([], parts);
    newRequiredParts[i].req_qty = value;
    newPart
      ? this.setState({ newRequiredParts })
      : this.setState({ requiredParts: newRequiredParts });
  };

  /*
   * Delete a part row via the remove button
   * @param i - Which required part to remove
   * @param {void}
   */
  deleteRow = async (i) => {
    await this.updateRequiredPart(i, "DELETE");
    this.getRequiredParts();
  };

  /*
   * Delete this PartList
   */
  delete = async () => {
    let res = await this.apiQuery(
      `${baseURI}/partlist/${this.props.match.params.id}`,
      "DELETE",
      null,
      `Successfully deleted the PartList ${this.props.match.params.id}`,
      `Failed to delete the PartList ${this.props.match.params.id}`
    );
    if (res.ok) window.location = `${window.location.origin}/partlist/`;
  };

  /* Find phantom parts */
  changePhantomPartNumber = async (phantomWPN) => {
    /* Display new part number */
    this.setState({ phantomWPN }, () => {
      /* If it meet the criteria, we can also fetch phantom parts */
      if (!isValidPartNumber(phantomWPN)) {
        this.setState({ phantomParts: null });
        return;
      }

      this.fetchPhantomParts(phantomWPN);
    });
  };

  fetchPhantomParts = async (phantomWPN) => {
    const response = await this.QueryM2mApi("inboms", phantomWPN);
    if (response.result.length > 0) {
      this.setState({
        phantomParts: response.result.map((p) => ({
          partNumber: p.fcomponent.replace(/\s/g, ""),
          rev: p.fcomprev.replace(/\s/g, ""),
          reqQty: Number(p.fqty.replace(/\s/g, "")),
          description: p.fdescript.replace(/\s{2,}/g, ""),
        })),
      });
    } else {
      this.setState({ phantomParts: null });
    }
  };

  addPhantomParts = async () => {
    /* Keep user from posting duplicates during update  */
    this.setState({ updateInProgress: true });

    let phantomPartQueries = [];
    for (let part of this.state.phantomParts) {
      phantomPartQueries.push(
        this.apiQuery(
          `${baseURI}/requiredpart`,
          "POST",
          JSON.stringify({
            number: part.partNumber,
            revision: part.rev,
            req_qty: part.reqQty,
            kitted_qty: 0,
            shiplist_id: this.state.id,
          }),
          `Added part ${part.partNumber} to part list!`,
          `Unable to add ${part.partNumber} to part list.`
        )
      );
    }

    await Promise.all(phantomPartQueries);

    /* After posting, reset the phantom BOM panel */
    this.setState(
      {
        updateInProgress: false,
        phantomWPN: "",
        phantomParts: [],
      },
      () => {
        this.getRequiredParts();
      }
    );
  };

  /*
   * Create a list of components representing the required parts
   */
  populateRequiredPartsList = () => {
    return this.state.requiredParts.map((p, i) => (
      <RequiredPartRow
        partNumber={p.part.number}
        revision={p.part.revision}
        description={p.part.description}
        reqQty={p.req_qty}
        originalQty={p.originalQty}
        weight={p.part.weight}
        key={i}
        i={i}
        updateRequiredQty={this.updateRequiredQty}
        updateWeight={this.updateWeight}
        deleteRow={this.deleteRow}
        translations={p.translations}
        customsCodes={p.part.customs_codes}
        languages={this.state.languages}
        unitOfMeasure={p.part.unitOfMeasure}
      />
    ));
  };

  populateNewRequiredParts = () => {
    return this.state.newRequiredParts.map((p, i) => (
      <AddPartRow
        partNumber={p && p.part ? p.part.number : null}
        revisions={p.revisions || []}
        updateQty={(p && p.req_qty) || ""}
        focusedRevision={p && p.focusedRevision}
        onChange={this.selectRevision}
        setPartNum={this.setPartNum}
        description={p && p.part ? p.part.description : null}
        editRequiredQty={this.editRequiredQty}
        key={i}
        i={i}
      />
    ));
  };

  render = () => {
    return (
      <div className="ship-list-show">
        <Status
          isLoading={this.state.isLoading}
          success={this.state.fetchSuccess}
          message={this.state.fetchMsg}
        />
        <div className="shipment">
          <div className="partlist-info">
            <label className="title">PartList: {this.state.id}</label>
            <label className="dropdown-label">Shipment</label>
            <FilterDropdown
              selected={this.state.selectedShipment}
              options={[
                { id: -1, value: "None" },
                ...this.state.shipments
                  .sort((a, b) => {
                    return (
                      Date.parse(a.ship_by_date) < Date.parse(b.ship_by_date)
                    );
                  })
                  .map((shipment) => ({
                    id: shipment.id,
                    value: shipment.ship_by_date,
                  })),
              ]}
              selectOption={this.selectShipment}
            />
          </div>
          <div className="button-panel">
            <SubmitBtn
              type="create"
              onClick={() =>
                this.props.history.push(`/partlist/labels/${this.state.id}`)
              }
            >
              Labels
            </SubmitBtn>
            <SubmitBtn
              type="edit"
              className="summary-btn"
              onClick={() =>
                window.open(
                  `${baseURI}/partlist/${this.state.id}/summary?Authorization=${
                    getAuthHeader().Authorization
                  }`
                )
              }
            >
              Part Summary
            </SubmitBtn>
            <div style={{ cursor: "help" }}>
              <input
                type="checkbox"
                checked={this.state.turboTranslations}
                onClick={() => {
                  this.setState({
                    turboTranslations: !this.state.turboTranslations,
                  });
                }}
              />
              <div title="Check this box to load translations faster by fetching them in parallel">
                Turbo Translations
              </div>
            </div>
          </div>
        </div>
        <div className="job-orders">
          <label className="job-orders-label">Job Orders</label>
          <input
            className="job-orders-input"
            onChange={(e) => {
              this.setJos(e.target.value);
            }}
            value={this.state.jos}
          />
          <SubmitBtn
            type="save"
            onClick={() => {
              this.patchJobOrders();
              if (
                this.state.associatedShipment !== this.state.selectedShipment
              ) {
                this.updateAssociatedShipment();
              }
            }}
          >
            Save
          </SubmitBtn>
        </div>
        <div className="required-parts-container">
          <h2>Required Parts</h2>
          <Labels
            labels={[
              { label: "Part #", filter: "part.number" },
              { label: "Rev", filter: "part.revision" },
              { label: "Description", filter: "part.description" },
              { label: "Required", filter: "req_qty" },
              { label: "Weight", filter: "part.weight" },
              { label: "Units", filter: "part.unitOfMeasure" },
            ]}
            sort={this.setSortFilter}
            selectedSort={this.state.sort}
            sortDescending={this.state.sortDescending}
            object="requiredParts"
          />
          {this.populateRequiredPartsList()}
          {this.populateNewRequiredParts()}
          <SubmitBtn
            type="save"
            disabled={this.state.updateInProgress}
            onClick={() => {
              this.updateRequiredParts();
              if (
                this.state.associatedShipment !== this.state.selectedShipment
              ) {
                this.updateAssociatedShipment();
              }
            }}
          >
            Save
          </SubmitBtn>
        </div>
        <div className="phantom-parts-container">
          <h2>Add Phantom Part</h2>
          <PhantomFrame
            phantomWPN={this.state.phantomWPN}
            phantomParts={this.state.phantomParts}
            changePhantomPartNumber={this.changePhantomPartNumber}
            addPhantomParts={this.addPhantomParts}
            submitDisabled={
              this.state.updateInProgress ||
              !this.state.phantomParts ||
              this.state.phantomParts.length === 0
            }
          >
            <Labels
              labels={[
                { label: "Part #", filter: "partNumber" },
                { label: "Rev", filter: "rev" },
                { label: "Description", filter: "description" },
                { label: "Required", filter: "reqQty" },
              ]}
              sort={this.setSortFilter}
              selectedSort={this.state.sort}
              sortDescending={this.state.sortDescending}
              object="phantomParts"
            />
          </PhantomFrame>
        </div>
        {this.state.deletedParts.length > 0 && (
          <div className="deleted-parts-container">
            <h2>Deleted Parts</h2>
            <p>
              <i>
                The following parts were deleted but had a kitted quantity at
                time of deletion.
              </i>
            </p>
            <Labels
              labels={[
                { label: "Part Number", filter: "part.number" },
                { label: "Rev", filter: "part.revision" },
                { label: "Description", filter: "part.description" },
                { label: "Qty Kitted", filter: "kitted_qty" },
                { label: "Units", filter: "part.unitOfMeasure" },
              ]}
              sort={() => {}}
              selectedSort={() => {}}
              sortDescending={() => {}}
            />
            {this.state.deletedParts.map((part) => (
              <DeletedPartRow
                partNumber={part.part.number}
                revision={part.part.revision}
                description={part.part.description}
                kittedQty={part.kitted_qty}
                key={part.id}
                id={part.id}
                onChange={() => {}}
                toPack={0}
                totalPacked={0}
                qtyPerBag={part.qty_per_bag}
                userWeightEach={part.part.weight || ""}
                setWeightEach={() => {}}
                editQtyPerBag={() => {}}
                unitOfMeasure={part.part.unitOfMeasure}
              />
            ))}
          </div>
        )}
        <AuditLog
          requiredParts={this.state.requiredParts || []}
          deletedParts={this.state.deletedParts || []}
        />
        <div className="action-container">
          <SubmitBtn
            type="delete"
            disabled={this.state.updateInProgress}
            onClick={() => {
              if (
                window.confirm(
                  `This will delete the PartList for Job Orders ${this.state.jos}`
                )
              ) {
                this.delete();
              }
            }}
          >
            Delete
          </SubmitBtn>
        </div>
      </div>
    );
  };
}

export default PartListShow;
