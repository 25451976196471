import React, { Component } from "react";
import { Link } from "react-router-dom";

import { baseURI } from "../../config/host-info";
import setLoadingStatus from "../../helpers/loading-status";
import apiQuery from "../../helpers/api-query";

import SubmitBtn from "../presentational/SubmitBtn";
import ShipmentTabMenu from "../presentational/ShipmentTabMenu";
import Status from "../presentational/Status";
import Pagination from "../presentational/Pagination";

/*
 * Contains a list of shipments
 */
class ShipmentIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userFilter: "",
      tabFilter: "all",
      shipments: [],
      isLoading: false,
      fetchSuccess: null,
      fetchMsg: "",
      pagInputVal: 0,
      currentPage: 0,
      numPages: null,
      resultsPerPage: null,
    };

    this.setLoadingStatus = setLoadingStatus.bind(this);
    this.apiQuery = apiQuery.bind(this);
  }

  componentDidMount = () => {
    // If we're on a non-zero offset, we should fetch that data for the user
    let currentPage = Number(window.location.hash.split(/page=/)[1]) || 0;
    this.setState({ currentPage: currentPage }, () => {
      this.getShipments();
    });
    this.props.history.push(`/shipment#page=${currentPage}`);

    // User presses the back button
    window.onpopstate = () => {
      this.setState(
        {
          currentPage: Number(window.location.hash.split(/page=/)[1]),
        },
        () => {
          this.getShipments();
        }
      );
    };
  };

  /*
   * User edits the filter input value to filter by JOs/shipment number
   * @param { string } value - User entered value
   */
  editInputFilter = (value) => {
    this.setState({ userFilter: value });
  };

  /*
   * Filter shipments based upon the user inputted JOs/shipment number
   * @param { array } shipments - list of shipments
   */
  filterShipments = (shipments) => {
    return shipments.filter((shipment) => {
      return (
        shipment.jos
          .toLowerCase()
          .indexOf(this.state.userFilter.toLocaleLowerCase()) !== -1 ||
        String(shipment.id)
          .toLowerCase()
          .indexOf(this.state.userFilter.toLocaleLowerCase()) !== -1
      );
    });
  };

  /*
   * User clicks the filter tab
   * @param { string } value - Which tab was clicked
   */
  editTabFilter = (value) => {
    this.setState({ tabFilter: value });
  };

  /*
   * Get list of shipments from database
   */
  getShipments = async () => {
    let response = await this.apiQuery(
      `${baseURI}/shipment?offset=${
        this.state.currentPage * this.state.resultsPerPage
      }`,
      "GET",
      null,
      `Successfully retrieved a list of shipments.`,
      `Failed to retrieve a list of shipments`
    );
    let json = response.ok ? await response.json() : [];
    this.setState({
      shipments: json,
      numPages: Math.ceil(
        response.headers.get("X-Total-Record-Count") /
          response.headers.get("X-Count-Size")
      ),
      resultsPerPage: Number(response.headers.get("X-Count-Size")),
    });
  };

  /*
   * Set the user entered pagination page
   * @param {string | number} value - User entered page
   * @param {boolean} clicked - Whether the user clicked on a pagination page or not
   */
  setPage = (value, clicked) => {
    this.setState(
      {
        pagInputVal: value,
        currentPage: Math.min(Math.max(Number(value), 0), this.state.numPages),
      },
      () => {
        if (clicked) {
          this.props.history.push(`/shipment#page=${this.state.currentPage}`);
          this.getShipments();
        }
      }
    );
  };

  /*
   * Load data from the newly selected pagination page
   */
  loadPage = () => {
    this.getShipments();
  };

  render = () => {
    return (
      <div className="shipment-index">
        <Status
          isLoading={this.state.isLoading}
          success={this.state.fetchSuccess}
          message={this.state.fetchMsg}
        />
        <label className="shipment-label">Shipments</label>
        <input
          className="shipment-filter-input"
          value={this.state.userFilter}
          onChange={(e) => {
            this.editInputFilter(e.target.value);
          }}
          placeholder="Filter shipments..."
        />
        <Link to="shipment/create">
          <SubmitBtn type="create" onClick={null}>
            Create
          </SubmitBtn>
        </Link>
        <ShipmentTabMenu
          activeTab={this.state.tabFilter}
          onClick={this.editTabFilter}
          values={this.filterShipments(this.state.shipments)}
        />
      </div>
    );
  };
}

export default ShipmentIndex;
