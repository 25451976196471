import React, { Component } from "react";

import SubmitBtn from "../presentational/SubmitBtn";
import PartListRow from "../presentational/PartListRow";

import setLoadingStatus from "../../helpers/loading-status";
import { baseURI } from "../../config/host-info";
import apiQuery from "../../helpers/api-query";

/*
 * Container for shipment pages
 */
class PartListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partLists: [],
    };

    this.apiQuery = apiQuery.bind(this);
    this.setLoadingStatus = setLoadingStatus.bind(this);
  }

  componentDidMount = async () => {
    this.getPartLists();
  };

  populatePartLists = () => {
    return this.state.partLists.map((partList) => {
      return (
        <div className="row" key={partList["id"]}>
          <PartListRow
            type="default"
            partListNumber={partList["id"]}
            jobOrders={partList["jos"]}
            key={partList["id"]}
            id={partList["id"]}
            shipmentNumber={partList["shipment_id"]}
          />
          <SubmitBtn
            type="create"
            onClick={() => {
              window.open(`/partlist/show/${partList["id"]}`);
            }}
          >
            View
          </SubmitBtn>
          <SubmitBtn
            type="save"
            onClick={() => this.props.copy(partList["id"])}
          >
            Copy
          </SubmitBtn>
        </div>
      );
    });
  };

  getPartLists = async () => {
    let res = await this.apiQuery(
      `${baseURI}/partlist`,
      "GET",
      null,
      "Successfully retrieved partlists",
      "Failed to retrieve partlists from server"
    );

    let json = res.ok ? await res.json() : [];
    this.setState({ partLists: json });
  };

  render = () => {
    return (
      <div className="partlist-modal">
        <div className="overlay" />
        <div className="close-container">
          <SubmitBtn type="delete" onClick={this.props.close}>
            X
          </SubmitBtn>
        </div>
        <h1>Copy PartList</h1>
        {this.state.partLists.length > 0 ? (
          <div className="partlists">{this.populatePartLists()}</div>
        ) : (
          <h2 className="loading-msg">Loading...</h2>
        )}
      </div>
    );
  };
}

export default PartListModal;
