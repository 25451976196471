import React, { PureComponent } from "react";

/*
 * Displays a loading icon or the result of an API
 * request with information (e.g. success or failure)
 */
class Status extends PureComponent {
  render = () => {
    let status = null;
    if (this.props.isLoading) {
      status = (
        <div className="loading-icon loading">
          <div className="loading-square">
            <div className="square first" />
            <div className="square second" />
            <div className="square third" />
            <div className="square fourth" />
          </div>
          <div className="status-text">loading</div>
        </div>
      );
    } else if (this.props.success === null || this.props.message === "") {
      status = <div className="dormant"> </div>;
    } else {
      status = (
        <div className={this.props.success ? `success` : `failure`}>
          {this.props.message}
        </div>
      );
    }
    return <div className="status">{status}</div>;
  };
}

export default Status;
