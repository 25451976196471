import React, { PureComponent } from "react";

/*
 * Part row containing kitted part information
 */
class DeletedPartRow extends PureComponent {
  viewPart = () =>
    window.open(`/part#p=${this.props.partNumber}r=${this.props.revision}`);

  render = () => (
    <div className="deleted-part-row">
      <div className="info">
        <div className="part-number" onClick={this.viewPart}>
          {this.props.partNumber || "?"}
        </div>
        <div className="revision" onClick={this.viewPart}>
          {this.props.revision || "?"}
        </div>
        <div className="description" onClick={this.viewPart}>
          {this.props.description || "?"}
        </div>
      </div>
      <div className="update-qty">
        <div className="kitted-qty">
          {this.props.kittedQty % 1 == 0 && this.props.unitOfMeasure == "EA"
            ? Math.trunc(this.props.kittedQty)
            : this.props.kittedQty}
        </div>
        <label className="unit-of-measure">{this.props.unitOfMeasure}</label>
      </div>
    </div>
  );
}

export default DeletedPartRow;
