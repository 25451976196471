/* API host information */
export let hostInfo = {
  name: process.env.REACT_APP_API_HOST || "localhost",
  apiPort: process.env.REACT_APP_API_PORT || 8000,
  protocol: process.env.REACT_APP_API_PROTOCOL || "http://",
};

if (process.env.NODE_ENV === "production") {
  hostInfo = {
    name: "api.shipcoordinator.com",
    apiPort: 443,
    protocol: "https://",
  };
}

export const port = process.env.REACT_APP_PORT || "3000";

export const auth = {
  domain: process.env.REACT_APP_AUTH_DOMAIN || "wasson-ece.auth0.com",
  clientID:
    process.env.REACT_APP_AUTH_CLIENT_ID || "zKQBJcVvjZfx5sosj86r4mo5oXpOj1nb",
  redirectUri:
    process.env.NODE_ENV === "production"
      ? "https://shipcoordinator.com/callback"
      : "http://localhost:3000/callback",
  audience:
    process.env.REACT_APP_AUTH_AUDIENCE || `https://api.shipcoordinator.com`,
  responseType: "token id_token",
  scope: "openid profile read:global write:global",
};

/* Built base host API URI */
export const baseURI = `${hostInfo.protocol}${hostInfo.name}:${hostInfo.apiPort}`;

/* Base URI to generate BCG codes */
export const BCGCodeURI = `${hostInfo.protocol}api.shipcoordinator.com/appdb/api/generateBCGcode93.php?string=`;

/* Base URI to AppDB's M2M API */
export const AppDbM2mApiUri = `${hostInfo.protocol}api.shipcoordinator.com/appdb/api/m2m/?key=wwNs3cSeWlKOMjgYyyCAb6uhLgfHUfvw`;

/* Full URI of the Wasson logo */
export const wassonLogo = `https://wasson-ece.imgix.net/global/Instrument%20Logo.png`;
