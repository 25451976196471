import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./About.scss";
const pkg = require("../../../package.json");
import { hash } from "../../config/hash";

/*
 * About component which describes app to the user
 */
class About extends Component {
  render = () => {
    return (
      <div className="about-container">
        <code>
          Version <b>{pkg.version}</b>
        </code>
        <code>
          Hash <b>{hash}</b>
        </code>
      </div>
    );
  };
}

export default About;
