import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Router, Switch, Route } from "react-router-dom";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import "./index.css";
import App from "./components/container/App";
import { unregister } from "./registerServiceWorker";
import history from "./helpers/history";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import root from "./reducers/root";
import SingleSignIn from "./components/Authentication/SingleSignIn";
import Login from "./components/Authentication/Login";

const store = createStore(root, composeWithDevTools(applyMiddleware(thunk)));
const render = () =>
  ReactDOM.render(
    <Router history={history}>
      <Provider store={store}>
        <Switch>
          <Route path="/callback" component={Login} />
          <App />
        </Switch>
      </Provider>
    </Router>,
    document.getElementById("root")
  );
store.subscribe(render);
render();
unregister();
