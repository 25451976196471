import React, { Component } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { clearLocalProfileState } from "../../helpers/auth";

/*
 * Navigation bar to reach Part, PartList index, and Shipment index
 */
class Navbar extends Component {
  render() {
    return (
      <div className="navbar">
        <Link to="/">
          <div className={`logo`}>
            <mark>Ship</mark>Coordinator
          </div>
        </Link>
        <div className="navlinks">
          <div className={`navlink login`} onClick={this.props.handleLogout}>
            Logout
          </div>
          <Link to="/about">
            <div
              className={`navlink about-link ${
                window.location.pathname === "/about" ? "active" : ""
              }`}
            >
              About
            </div>
          </Link>
          <Link to="/part">
            <div
              className={`navlink part-link ${
                window.location.pathname === "/part" ? "active" : ""
              }`}
            >
              Part
            </div>
          </Link>
          <Link to="/part-creation">
            <div
              className={`navlink part-link ${
                window.location.pathname === "/part-creation" ? "active" : ""
              }`}
            >
              Create Part
            </div>
          </Link>
          <Link to="/partlist">
            <div
              className={`navlink partlist-link ${
                window.location.pathname === "/partlist" ? "active" : ""
              }`}
            >
              PartList
            </div>
          </Link>
          <Link to="/shipment">
            <div
              className={`navlink shipment-link ${
                window.location.pathname === "/shipment" ? "active" : ""
              }`}
            >
              Shipment
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

const mapDispatch = (dispatch) => ({
  handleLogout: () => {
    dispatch({
      type: "CLEAR_PROFILE",
    });
    clearLocalProfileState();
  },
});

export default connect(null, mapDispatch)(Navbar);
