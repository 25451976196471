import React, { PureComponent } from "react";

import SubmitBtn from "./SubmitBtn";
import { isM2MPart } from "../../helpers/valid-part";

/*
 * Part row containing kitted part information
 */
class PartInfo extends PureComponent {
  render = () => {
    return (
      <div className="part-info">
        <label className="header">Detailed Part Information</label>
        <div className="values">
          <div className="description">
            <label>Description</label>
            {/* <div className="value">{this.props.desc || "?"}</div> */}
            <input
              value={this.props.desc || ""}
              disabled={!this.props.number || isM2MPart(this.props.number)}
              onChange={(e) => {
                this.props.editPartDetails("userDescription", e.target.value);
              }}
            />
          </div>
          <div className="extended-description">
            <label>Extended Description</label>
            <input
              value={this.props.extendedDesc || ""}
              disabled={!this.props.number || isM2MPart(this.props.number)}
              onChange={(e) => {
                this.props.editPartDetails(
                  "userExtendedDescription",
                  e.target.value
                );
              }}
            />
          </div>
          <div className="cost">
            <label>Cost</label>
            {/* <div className="value">
              {this.props.cost ? Number(this.props.scost).toFixed(2) : "?"}
            </div> */}
            <input
              value={this.props.userCostEach || this.props.cost || ""}
              disabled={!this.props.number || isM2MPart(this.props.number)}
              onChange={(e) => {
                this.props.editPartDetails("userCostEach", e.target.value);
              }}
            />
          </div>
          <div className="weight-each">
            <label>Weight Each</label>
            <input
              id="weight-each"
              value={this.props.weight == 0 ? "" : this.props.weight}
              onChange={(e) => {
                this.props.editPartDetails("userWeight", e.target.value);
              }}
            />
          </div>
          <div className="material">
            <label>Material</label>
            <input
              id="material"
              type="text"
              value={this.props.material || ""}
              onChange={(e) => {
                this.props.editPartDetails("userMaterial", e.target.value);
              }}
            />
          </div>
          <SubmitBtn type="save" onClick={this.props.savePartDetails}>
            Save
          </SubmitBtn>
        </div>
      </div>
    );
  };
}

export default PartInfo;
