import React, { PureComponent } from "react";

import "./PhantomPart.css";

class PhantomPart extends PureComponent {
  render = () => (
    <div className="phantom-part">
      <div className="part-number">{this.props.partNumber}</div>
      <div className="revision">{this.props.rev}</div>
      <div className="description">{this.props.description}</div>
      <div className="req-qty">{this.props.reqQty}</div>
    </div>
  );
}

export default PhantomPart;
