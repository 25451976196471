import React, { PureComponent } from "react";

/*
 *
 */
class QueuedPartRow extends PureComponent {
  viewPart = () =>
    window.open(`/part#p=${this.props.partNumber}r=${this.props.revision}`);

  render = () => {
    if (this.props.requiredQty - this.props.unitsKitted !== 0) {
      return (
        <div className="queued-part-row">
          <div className="info">
            <div className="part-number" onClick={this.viewPart}>
              {this.props.partNumber || ""}
            </div>
            <div className="revision" onClick={this.viewPart}>
              {this.props.revision || ""}
            </div>
            <div className="description" onClick={this.viewPart}>
              {this.props.description || ""}
            </div>
            <div className="location" onClick={this.viewPart}>
              <div className="loc">{this.props.location || "?"}/</div>
              <div className="bin">{this.props.bin || "?"}/</div>
              <div className="on-hand">
                {Math.trunc(this.props.onHand) || "?"}
              </div>
            </div>
          </div>
          <div className="update-qty">
            <div className="required-qty">
              {this.props.requiredQty % 1 == 0 &&
              this.props.unitOfMeasure == "EA"
                ? Math.trunc(this.props.requiredQty)
                : this.props.requiredQty}
            </div>
            <div className="remaining-qty">
              {
                // If the user is specifying the total kitted qty,
                // display that instead of the calculated qty
                !this.props.userQtyKitted
                  ? (
                      this.props.requiredQty -
                      (this.props.userQtyKitted + this.props.unitsKitted)
                    ).toFixed(2)
                  : (this.props.requiredQty - this.props.userQtyKitted).toFixed(
                      2
                    )
              }
            </div>
            <input
              className="kitted-qty"
              onChange={(e) =>
                this.props.setQtyKitted(this.props.id, e.target.value)
              }
              value={this.props.userQtyKitted || ""}
            />
            <label className="unit-of-measure">
              {this.props.unitOfMeasure}
            </label>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };
}

export default QueuedPartRow;
