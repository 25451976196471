import * as React from "react";
import { connect } from "react-redux";
import { baseURI } from "../../config/host-info";
import setLoadingStatus from "../../helpers/loading-status";
import apiQuery from "../../helpers/api-query";
import "./CreatePart.css";

class CreatePart extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isSubmitDisabled: false };

    this.setLoadingStatus = setLoadingStatus.bind(this);
    this.apiQuery = apiQuery.bind(this);
  }

  editNumber = (event) => {
    let value = event.target.value;
    if (!value || value[0] !== "F") value = `F${value}`;
    this.props.editNewPartAttribute("number", value.toUpperCase());
  };
  editRevision = (event) =>
    this.props.editNewPartAttribute("revision", event.target.value);
  editDescription = (event) =>
    this.props.editNewPartAttribute("description", event.target.value);
  editExtendedDescription = (event) =>
    this.props.editNewPartAttribute("extendedDescription", event.target.value);
  editMaterial = (event) =>
    this.props.editNewPartAttribute("material", event.target.value);
  editUsedFor = (event) =>
    this.props.editNewPartAttribute("usedFor", event.target.value);
  editCountryOfOrigin = (event) =>
    this.props.editNewPartAttribute("countryOfOrigin", event.target.value);
  editCostEach = (event) =>
    this.props.editNewPartAttribute("costEach", event.target.value);
  editWeight = (event) =>
    this.props.editNewPartAttribute("weight", event.target.value);
  editUnitOfMeasure = (event) =>
    this.props.editNewPartAttribute("unitOfMeasure", event.target.value);

  isValidNewPart = () => {
    const { newPart } = this.props;

    if (!newPart) return false;
    if (
      !newPart.number ||
      newPart.number[0] !== "F" ||
      newPart.number.length < 4 ||
      !newPart.revision ||
      !newPart.description ||
      !newPart.unitOfMeasure
    )
      return false;

    return true;
  };

  createPart = async () => {
    this.setState({ isSubmitDisabled: false });
    const { newPart } = this.props;
    let postResult = await this.apiQuery(
      `${baseURI}/${newPart.number}`,
      "POST",
      JSON.stringify(newPart),
      `Successfully created part ${newPart.number}`,
      `Failed to create part ${newPart.number}`
    );
    this.setState({ isSubmitDisabled: false });

    if (postResult.ok) {
      this.props.history.push(`/part#p=${newPart.number}r=${newPart.revision}`);
    }
  };

  render = () => {
    const { newPart } = this.props;
    return (
      <div className="part-creation">
        <div className="part-identifier">
          <input
            type="text"
            name="Number"
            required
            minLength={4}
            maxLength={35}
            placeholder="Part Number"
            value={newPart.number || ""}
            onChange={this.editNumber}
          />
          <input
            type="text"
            name="Revision"
            className="revision"
            required
            minLength={1}
            maxLength={4}
            placeholder="Revision"
            value={newPart.revision || ""}
            onChange={this.editRevision}
          />
        </div>
        <input
          type="text"
          name="Description"
          required
          maxLength={35}
          placeholder="Description"
          value={newPart.description || ""}
          onChange={this.editDescription}
        />
        <input
          type="text"
          name="Extended Description"
          required
          minLength={4}
          maxLength={32}
          placeholder="Extended Description"
          value={newPart.extendedDescription || ""}
          onChange={this.editExtendedDescription}
        />
        <input
          type="text"
          name="Material"
          required
          placeholder="Material"
          value={newPart.material || ""}
          onChange={this.editMaterial}
        />
        <input
          type="text"
          name="Used For"
          required
          placeholder="Used For"
          value={newPart.usedFor || ""}
          onChange={this.editUsedFor}
        />
        <input
          type="text"
          name="Country of Origin"
          required
          placeholder="Country of Origin"
          value={newPart.countryOfOrigin || ""}
          onChange={this.editCountryOfOrigin}
        />
        <div className="cost">
          <input
            type="number"
            name="Cost"
            required
            placeholder="Cost"
            value={newPart.costEach || ""}
            onChange={this.editCostEach}
          />
          <select
            name="Unit of Measure"
            required
            placeholder="Unit of Measure"
            value={newPart.unitOfMeasure || ""}
            onChange={this.editUnitOfMeasure}
          >
            <option>EA</option>
            <option>FT</option>
            <option>PKG</option>
            <option>ROLL</option>
          </select>
        </div>
        <input
          type="number"
          name="Weight"
          required
          placeholder="Weight"
          value={newPart.weight || ""}
          onChange={this.editWeight}
        />
        <button
          type="submit"
          onClick={(e) => this.createPart(e)}
          disabled={
            this.state.isSubmitDisabled || !this.isValidNewPart(newPart)
          }
        >
          Submit
        </button>
      </div>
    );
  };
}

const mapState = (state) => ({ newPart: state.newPart });

const mapDispatch = (dispatch) => ({
  editNewPartAttribute: (field, value) =>
    dispatch({
      type: "SET_NEW_PART_ATTRIBUTE",
      attribute: { [field]: value },
    }),
  clearNewPart: () =>
    dispatch({
      type: "CLEAR_NEW_PART",
    }),
});

export default connect(mapState, mapDispatch)(CreatePart);
