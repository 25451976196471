import React, { PureComponent } from "react";

import SubmitBtn from "../presentational/SubmitBtn";
import PhantomPart from "../presentational/PhantomPart";

/*
 * Container for shipment pages
 */
class PhantomFrame extends PureComponent {
  constructor(props) {
    super(props);
  }

  render = () => (
    <div className="phantom-frame">
      <div className="search-area">
        <input
          className="phantom-wpn-input"
          onChange={(e) => {
            this.props.changePhantomPartNumber(e.target.value);
          }}
          value={this.props.phantonWPN}
          placeholder="Phantom Part Number..."
        />
      </div>
      <div className="results-area">
        {this.props.children}
        {this.props.phantomParts
          ? this.props.phantomParts.map((p) => (
              <PhantomPart
                key={`${p.partNumber}-${p.rev}`}
                partNumber={p.partNumber}
                rev={p.rev}
                reqQty={p.reqQty}
                description={p.description}
              />
            ))
          : null}
      </div>
      <SubmitBtn
        type="save"
        onClick={this.props.addPhantomParts}
        disabled={this.props.submitDisabled}
      >
        Add Phantom Parts
      </SubmitBtn>
    </div>
  );
}

export default PhantomFrame;
