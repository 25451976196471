/*
 * Does this number represent a valid part number?
 * @param {string | number} partNumber - potentially shitty part number
 * @return {boolean} - is valid part number
 */
const isValidPart = (partNumber) => {
  return partNumber.length > 3; // cool, bro.
};

const isM2MPart = (number) => number[0] !== "F";

export default isValidPart;
export { isM2MPart };
