import React, { PureComponent } from "react";

import Dropdown from "./Dropdown";
import SubmitBtn from "./SubmitBtn";

/*
 * Drop down menu
 */
class TranslationRow extends PureComponent {
  render = () => {
    return (
      <div className="translation-row">
        <div className="language-container">
          <label>Language</label>
          <Dropdown
            options={this.props.languages.map((l) => l.code)}
            value={this.props.language}
            type="translation"
            onChange={(e) => {
              this.props.editTranslation(
                e.target.value,
                this.props.index,
                "language"
              );
            }}
          />
        </div>
        <div className="description-container">
          <label>Description</label>
          <input
            className="description"
            value={this.props.description}
            onChange={(e) => {
              this.props.editTranslation(
                e.target.value,
                this.props.index,
                "description"
              );
            }}
          />
          <label>Extended Description</label>
          <textarea
            className="extended-description"
            value={this.props.extendedDescription || ""}
            onChange={(e) => {
              this.props.editTranslation(
                e.target.value,
                this.props.index,
                "extendedDescription"
              );
            }}
          />
          <label>Used For</label>
          <textarea
            className="used-for"
            value={this.props.usedFor || ""}
            onChange={(e) => {
              this.props.editTranslation(
                e.target.value,
                this.props.index,
                "used_for"
              );
            }}
          />
          <label>Materials</label>
          <input
            className="materials"
            value={this.props.materials}
            onChange={(e) => {
              this.props.editTranslation(
                e.target.value,
                this.props.index,
                "materials"
              );
            }}
          />
          <SubmitBtn
            type="delete"
            onClick={() => {
              this.props.deleteTranslation(
                this.props.language,
                this.props.index
              );
            }}
          >
            Remove
          </SubmitBtn>
        </div>
      </div>
    );
  };
}

export default TranslationRow;
