import React, { PureComponent } from "react";

import { CustomsCode } from "../../helpers/Enums";
import SubmitBtn from "./SubmitBtn";
import TranslationBadge from "./TranslationBadge";
import CustomsCodesBadge from "./CustomsCodesBadge";

/*
 * Part required within a partlist
 */
class RequiredPartRow extends PureComponent {
  constructor(props) {
    super(props);
  }

  /* Get a list of missing translations for a part */
  missingTranslations = () => {
    let translations = this.props.translations;
    if (!translations || translations.length === 0) {
      return this.props.languages.map((l) => l.code);
    }
    let missingTrans = [];
    for (let language of this.props.languages) {
      if (translations.findIndex((t) => t.language === language.code) === -1) {
        missingTrans.push(language.code);
      }
    }

    return missingTrans;
  };

  /* Get a list of missing customs codes for a part */
  missingCustomsCodes = () => {
    let codes = this.props.customsCodes;
    if (!codes) {
      return CustomsCode.enums.map((c) => c.key);
    }

    let missingCodes = [];
    for (let code of CustomsCode.enums) {
      if (codes.findIndex((c) => c.type === code.value) === -1) {
        missingCodes.push(code.key);
      }
    }

    return missingCodes;
  };

  viewPart = () =>
    window.open(
      `/part#p=${this.props.partNumber}r=${this.props.revision || ""}`
    );

  render = () => {
    return (
      <div className="required-part-row">
        <div className="badges">
          <TranslationBadge missingTranslations={this.missingTranslations()} />
          <CustomsCodesBadge
            missingCustomsCodes={this.missingCustomsCodes()}
            codes={this.props.customsCodes}
          />
        </div>
        <div className="part-number" onClick={this.viewPart}>
          {this.props.partNumber}
        </div>
        <div className="revision" onClick={this.viewPart}>
          {this.props.revision}
        </div>
        <div className="description" onClick={this.viewPart}>
          {this.props.description}
        </div>
        <div className="required-field">
          {/* <div className="remaining">
            {this.props.originalQty % 1 == 0 && this.props.unitOfMeasure == "EA"
              ? Math.trunc(this.props.originalQty)
              : Number(this.props.originalQty).toFixed(2)}
          </div> */}
          <input
            className="update-qty-input"
            onChange={(e) =>
              this.props.updateRequiredQty(this.props.i, e.target.value)
            }
            value={this.props.reqQty || this.props.originalQty}
          />
          <input
            className="update-weight-input"
            onChange={(e) =>
              this.props.updateWeight(this.props.i, e.target.value)
            }
            value={this.props.weight || this.props.originalWeight}
          />
        </div>
        <label className="units-of-measure">{this.props.unitOfMeasure}</label>
        <SubmitBtn
          type="delete"
          onClick={() => this.props.deleteRow(this.props.i)}
        >
          X
        </SubmitBtn>
      </div>
    );
  };
}

export default RequiredPartRow;
