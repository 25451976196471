import React, { PureComponent } from "react";
import SubmitBtn from "./SubmitBtn";

/*
 *
 */
class PalletAdd extends PureComponent {
  render = () => {
    return (
      <div className="pallet-add">
        <label className="title">Add Pallet</label>
        <div className="dimensions">
          <input
            className="pallet-input"
            onChange={(e) => {
              this.props.editDimension("width", e.target.value);
            }}
            value={this.props.dimensions.width}
            placeholder="W"
          />
          <input
            className="pallet-input"
            onChange={(e) => {
              this.props.editDimension("height", e.target.value);
            }}
            value={this.props.dimensions.height}
            placeholder="H"
          />
          <input
            className="pallet-input"
            onChange={(e) => {
              this.props.editDimension("depth", e.target.value);
            }}
            value={this.props.dimensions.depth}
            placeholder="D"
          />
        </div>
        <SubmitBtn
          onClick={() => {
            this.props.createPallet();
          }}
          type="save"
          disabled={this.props.disabled}
        >
          +
        </SubmitBtn>
      </div>
    );
  };
}

export default PalletAdd;
