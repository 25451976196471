import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import setLoadingStatus from "../../../helpers/loading-status";
import apiQuery from "../../../helpers/api-query";
import "./AuditLog.css";
import { baseURI } from "../../../config/host-info";

class PickList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auditLogs: [],
    };

    this.setLoadingStatus = setLoadingStatus.bind(this);
    this.apiQuery = apiQuery.bind(this);
  }

  componentDidMount = async () => {
    let auditLogs = await this.getAuditLogs(this.props.match.params.id);
    if (!auditLogs) return;
    this.setState({ auditLogs });
  };

  getAuditLogs = async (id) => {
    let response = await this.apiQuery(
      `${baseURI}/partlist/${id}/audit`,
      "GET",
      null,
      "",
      ""
    );
    if (!response || !response.ok) return null;

    let logs = await response.json();
    if (!logs || !logs.length) return null;
    return filterKittingChanges(logs.map(mapAuditLog));
  };

  render = () => {
    return (
      <div id="audit-log">
        <h2>Audit Log</h2>
        <div id="logs">
          {this.state.auditLogs.map((auditLog) => (
            <div key={auditLog.id}>
              <span>[{moment(auditLog.createdAt).fromNow()}] </span>
              <span className="audit-message">
                {auditToString(
                  auditLog,
                  this.props.requiredParts,
                  this.props.deletedParts
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };
}

const filterKittingChanges = (auditLogs) =>
  auditLogs.filter(
    (auditLog) =>
      auditLog.action !== "update" ||
      (auditLog.details &&
        auditLog.details.requiredQuantity &&
        !auditLog.details.kittedQuantity)
  );

const findPartById = (requiredParts, deletedParts, id) => {
  const requiredPartId = requiredParts.findIndex((part) => part.id === id);
  const deletedPartId = deletedParts.findIndex((part) => part.id === id);

  if (requiredPartId !== -1) return requiredParts[requiredPartId];
  if (deletedPartId !== -1) return deletedParts[deletedPartId];
  return null;
};

const auditToString = (auditLog, requiredParts = [], deletedParts = []) => {
  switch (auditLog.action) {
    case "delete":
      return auditDeleteToString(auditLog, deletedParts);
    case "create":
      return auditCreateToString(auditLog, requiredParts, deletedParts);
    case "update":
      return auditUpdateToString(auditLog, requiredParts, deletedParts);
    default:
      return "";
  }
};

const auditCreateToString = (auditLog, requiredParts, deletedParts) => {
  const createdPart = findPartById(
    requiredParts,
    deletedParts,
    auditLog.requiredPartId
  );
  return `${auditLog.userName} added part ${
    (createdPart && createdPart.part && createdPart.part.number) || "" || ""
  }`;
};

const auditUpdateToString = (auditLog, requiredParts, deletedParts) => {
  const updatedPart = findPartById(
    requiredParts,
    deletedParts,
    auditLog.requiredPartId
  );
  return `${auditLog.userName} updated part ${
    (updatedPart && updatedPart.part && updatedPart.part.number) || ""
  } from required quantity ${
    (auditLog.details &&
      auditLog.details.requiredQuantity &&
      auditLog.details.requiredQuantity.before) ||
    ""
  } to ${
    (auditLog.details &&
      auditLog.details.requiredQuantity &&
      auditLog.details.requiredQuantity.after) ||
    ""
  }`;
};

const auditDeleteToString = (auditLog, deletedParts) => {
  const deletedPart = findPartById([], deletedParts, auditLog.requiredPartId);
  return `${auditLog.userName} deleted part ${
    deletedPart && deletedPart.part && deletedPart.part.number
  }`;
};

const mapAuditLog = (auditLog) => ({
  id: auditLog.id,
  action: auditLog.action,
  requiredPartId: auditLog.content_id,
  createdAt: auditLog.created_at && moment.utc(auditLog.created_at),
  updatedAt: auditLog.updated_at && moment.utc(auditLog.updated_at),
  description: auditLog.description,
  details: auditLog.details && mapDetails(JSON.parse(auditLog.details)),
  userName: auditLog.user_name,
});

const mapDetails = (details) => ({
  requiredQuantity: details.req_qty && {
    before: Number(details.req_qty.before),
    after: Number(details.req_qty.after),
  },
  kittedQuantity: details.kitted_qty && {
    before: Number(details.kitted_qty.before),
    after: Number(details.kitted_qty.after),
  },
  updatedAt: details.updated_at && {
    before: moment.utc(details.updated_at.before),
    after: moment.utc(details.updated_at.after),
  },
});

export default withRouter(PickList);
