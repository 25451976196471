import Enum from "enum";

export const CustomsCode = new Enum(
  {
    GHS: 0,
    ScheduleB: 10,
    TARIC: 20,
  },
  {
    ignoreCase: true,
  }
);

export const Status = new Enum(
  {
    Started: 0,
    Pending: 10,
    Partial: 20,
    Complete: 100,
  },
  {
    ignoreCase: true,
  }
);
