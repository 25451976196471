import React, { PureComponent } from "react";

/*
 * Dropdown menu with filter-able input
 */
class FilterDropdown extends PureComponent {
  constructor() {
    super();

    this.state = {
      dropdownOpen: false,
      filter: "",
    };
  }

  /* Render a dropdown menu which is open */
  constructClosedDropdown = () => {
    let selectedIndex = this.props.options.findIndex(
      (option) => option.id == this.props.selected
    );

    return (
      <div className="filter-dropdown closed">
        <div
          className="filter-head closed-dropdown filter-dropdown-option"
          onClick={() => {
            this.setState({ dropdownOpen: !this.state.dropdownOpen });
          }}
        >
          <div className="option">
            {selectedIndex !== -1
              ? this.props.options[selectedIndex].value
              : this.props.options[0].value}
          </div>
          {selectedIndex === -1 ? null : (
            <div
              className="link"
              onClick={() => {
                window.open(
                  `/shipment/show/${this.props.options[selectedIndex].id}`
                );
              }}
            >
              →
            </div>
          )}
        </div>
      </div>
    );
  };

  /* Render a dropdown menu which is closed */
  constructOpenDropdown = () => {
    let selectedIndex = this.props.options.findIndex(
      (option) => option.id == this.props.selected
    );
    return (
      <div className="filter-dropdown open">
        <div className="filter-static-head">
          <div
            className="filter-head open-dropdown"
            onClick={() => {
              this.setState({ dropdownOpen: !this.state.dropdownOpen });
            }}
          >
            <div className="option">
              {selectedIndex !== -1
                ? this.props.options[selectedIndex].value
                : this.props.options[0].value}
            </div>
            {selectedIndex === -1 ? null : (
              <div
                className="link"
                onClick={() => {
                  window.open(
                    `/shipment/show/${this.props.options[selectedIndex].id}`
                  );
                }}
              >
                →
              </div>
            )}
          </div>
          <input
            className="filter-input"
            value={this.state.filter}
            placeholder="Filter..."
            onChange={(e) => {
              this.setState({ filter: e.target.value });
            }}
          />
        </div>
        <div className="options">
          {this.props.options.map((option, i) => {
            // Only output options which match the filter
            return String(option)
              .toLowerCase()
              .indexOf(this.state.filter.toLowerCase()) === 0 ? (
              <div
                className="filter-dropdown-option"
                onClick={() => {
                  this.props.selectOption(option.id);
                  this.setState({ dropdownOpen: false });
                }}
                key={i}
              >
                <div className="option">{option.value}</div>
                {option === "None" ? null : (
                  <div
                    className="link"
                    onClick={() => {
                      window.open(
                        `/shipment/show/${this.props.options[selectedIndex].id}`
                      );
                    }}
                  >
                    →
                  </div>
                )}
              </div>
            ) : null;
          })}
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div className="filter-dropdown-container">
        {this.state.dropdownOpen
          ? this.constructOpenDropdown()
          : this.constructClosedDropdown()}
      </div>
    );
  };
}

export default FilterDropdown;
