/* npm imports */
import React, {Component} from "react";
import {Link} from "react-router-dom";

/* Helper imports */
import {baseURI} from "../../config/host-info";
import setLoadingStatus from "../../helpers/loading-status";
import apiQuery from "../../helpers/api-query";

/* presentational component imports */
import SubmitBtn from "../presentational/SubmitBtn";
import Dropdown from "../presentational/Dropdown";
import PartListRow from "../presentational/PartListRow";
import KittedPartRow from "../presentational/KittedPartRow";
import QueuedPartRow from "../presentational/QueuedPartRow";
import PackedPartRow from "../presentational/PackedPartRow";
import PalletAdd from "../presentational/PalletAdd";
import Status from "../presentational/Status";
import {Labels, setSortFilter} from "../presentational/Labels";

import PartListModal from "./PartListModal";
import QueryM2mApi from "../../helpers/m2m-api";
import {getAuthHeader} from "../../helpers/auth";

/*
 * Contains details of a given shipment
 */
class ShipmentShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      partListModalOpen: false,
      isLoading: false,
      fetchSuccess: null,
      userFilter: "",
      partLists: [],
      focusedPartList: "All",
      focusedPallet: "New Pallet",
      focusedBox: "New Box",
      requiredParts: [],
      packedParts: [],
      pallets: [],
      boxes: [],
      newPallet: {
        width: "",
        depth: "",
        height: "",
      },
      sortDescending: true,
      sort: null,
      sortObject: null,
    };

    this.queuedPartLabels = [
      {label: "Part Number", filter: "part.number"},
      {label: "Rev", filter: "part.revision"},
      {label: "Description", filter: "part.description"},
      {label: "Location/Bin/OnHand", filter: "part.description"},
      {label: "Required Qty", filter: "req_qty"},
      {label: "Remaining", filter: null},
      {label: "Total Kitted", filter: null},
      {label: "Units", filter: "part.unitOfMeasure"},
    ];
    this.kittedPartLabels = [
      {label: "Part Number", filter: "part.number"},
      {label: "Rev", filter: "part.revision"},
      {label: "Description", filter: "part.description"},
      {label: "Qty Kitted", filter: "kitted_qty"},
      {label: "Qty to Pack", filter: null},
      {label: "Qty / Bag", filter: null},
      {label: "Remain", filter: null},
      {label: "Units", filter: "part.unitOfMeasure"},
      {label: "Weight Each", filter: null},
    ];
    this.packedPartLabels = [
      {label: "Part Number", filter: "requiredpart.part.number"},
      {label: "Rev", filter: "requiredpart.part.revision"},
      {label: "Description", filter: "requiredpart.part.description"},
      {label: "Pallet", filter: null},
      {label: "Box", filter: "box_id"},
      {label: "Qty To Unpack", filter: null},
      {label: "Units", filter: "requiredpart.part.unitOfMeasure"},
    ];

    this.setLoadingStatus = setLoadingStatus.bind(this);
    this.apiQuery = apiQuery.bind(this);
    this.setSortFilter = setSortFilter.bind(this);
    this.QueryM2mApi = QueryM2mApi.bind(this);
  }

  /*
   * Immediately after the component mounts, we should fetch the
   * PartLists associated with this shipment, and the required parts
   * associated with each PartList.
   */
  componentDidMount = async () => {
    // Have to get the partlists before we can get its required parts
    await this.getShipmentPartLists();
    this.getAllRequiredParts();
    this.getPallets();
    this.getPackedParts();
    this.getShipmentInfo();
    this.selectPallet("New Pallet");
  };

  /*
   * Set the partlist dropdown filter
   * @param { string } filter - User input string to filter parts
   */
  setPartsFilter = (filter) => {
    this.setState({userFilter: filter});
  };

  /*
   * Select a pallet from the pallet dropdown menu and fetch its
   * associated boxes
   * @param { string } value - selected pallet name
   */
  selectPallet = (value) => {
    this.setState({focusedPallet: value}, () => {
      //  Only fetch possible boxes if we're not using a new pallet
      if (value !== "New Pallet") {
        this.getBoxes();
      } else {
        this.setState({boxes: []});
      }
    });
  };

  /*
   * Select a box from the dropdown menu associated with a given pallet
   * @param { string } value - selected box name
   */
  selectBox = (value) => {
    this.setState({focusedBox: value});
  };

  /*
   * Filter parts based upon the user inputted JOs/shipment number
   */
  filterParts = () => {
    // We want parts that 1. Exist, 2. Contain the user filtered
    // description/partnumber, 3. Are in the user selected PartList, and
    // 4. Have at least 1 part remaining
    return this.state.requiredParts.filter((part) => {
      if (!part) return false;
      return (
        (part.part.description
            .toLowerCase()
            .indexOf(this.state.userFilter.toLowerCase()) !== -1 ||
          String(part.part.number)
            .toLowerCase()
            .indexOf(this.state.userFilter.toLowerCase())) !== -1 &&
        (part.partlist_id == Number(this.state.focusedPartList) ||
          this.state.focusedPartList === "All")
      );
    });
  };

  /*
   * Filter packed parts based upon the user inputted JOs/shipment number
   */
  filterPackedParts = () => {
    // We want parts that either 1. Exist, 2. Contain the user filtered
    // description/partnumber, and 3. Are in the user selected PartList
    return this.state.packedParts.filter((part) => {
      if (!part) return false;
      return (
        (part.requiredpart.part.description
            .toLowerCase()
            .indexOf(this.state.userFilter.toLowerCase()) !== -1 ||
          String(part.requiredpart.part.number)
            .toLowerCase()
            .indexOf(this.state.userFilter.toLowerCase())) !== -1 &&
        (part.requiredpart.shiplist_id == this.state.focusedPartList ||
          this.state.focusedPartList === "All")
      );
    });
  };

  /*
   * User selects a given partlist from the dropdown
   * @param { string } value - selected PartList id
   */
  selectPartList = (value) => {
    this.setState({focusedPartList: value});
  };

  /*
   * User sets the quantity to pack of a kitted part
   * @param { number } id - part.id of the part to update
   * @param { string } value - New numerical value
   */
  setQtyToPack = (id, value) => {
    // We can only pack the maximum remaining parts
    let part = this.state.requiredParts.find((part) => part.id === id);

    // Don't mutate state. Copy it, and assign our user's new value
    let newRequiredParts = Object.assign([], this.state.requiredParts);
    part.userToPack = value;
    this.setState({requiredParts: newRequiredParts});
  };

  /*
   * User adds a new pallet. POSTs a new pallet to the given shipment
   */
  createPallet = async () => {
    let creationEndpoint = `${baseURI}/pallet`;
    let res = await this.apiQuery(
      creationEndpoint,
      "POST",
      JSON.stringify(this.state.newPallet),
      "Successfully created a new pallet!",
      "Failed to make a new pallet."
    );
    if (!res.ok) {
      return;
    }
    let json = await res.json();

    // If it worked, we should reset the user's pallet info
    this.setState({
      newPallet: {width: "", height: "", depth: ""},
    });

    // Now we should associate the pallet with the shipment
    let associationEndpoint = `${baseURI}/pallet/${json.id}/shipment/${this.state.id}`;
    res = await this.apiQuery(
      associationEndpoint,
      "POST",
      null,
      `Successfully associated pallet ${json.id} with this shipment.`,
      `Failed to associate the pallet ${json.id} with this shipment.`
    );
    if (!res.ok) {
      return;
    }
    json = await res.json();

    await this.getPallets();

    // Now set our current pallet to the newly created one
    this.setState({
      focusedPallet: json.name,
    });
  };

  /*
   * Copy a given partlist and associate it with the current shipment
   * @param {number} id - Identifier of the partlist
   */
  copyPartList = async (id) => {
    // First make a copy of the partlist
    let res = await this.apiQuery(
      `${baseURI}/partlist/${id}/copy`,
      "POST",
      null,
      `Successfully copied partlist ${id}`,
      `Failed to copy partlist ${id}`
    );
    let json = res.ok ? await res.json() : null;

    // Proceed no further if we weren't successful in copying partlist
    if (!res.ok) {
      return;
    }

    // Then associate that partlist with this shipment
    res = await this.apiQuery(
      `${baseURI}/partlist/${json.id}/shipment/${this.state.id}`,
      "POST",
      null,
      `Successfully associated partlist ${json.id} with this shipment`,
      `Failed to associate partlist ${json.id} with this shipment`
    );

    // If all this worked, we should update the UI to reflect our new partlists
    if (res.ok) {
      await this.getShipmentPartLists();
      this.getAllRequiredParts();
      this.getPackedParts();
    }
  };

  /* Get an excel summary for the shipment */
  getExcelSummary = async () => {
    window.open(
      `${baseURI}/shipment/${this.state.id}/summary?Authorization=${
        getAuthHeader().Authorization
      }`
    );
  };

  /* Get an excel invoice for the shipment */
  getExcelInvoice = async () => {
    window.open(
      `${baseURI}/shipment/${this.state.id}/invoice?Authorization=${
        getAuthHeader().Authorization
      }`
    );
  };

  /*
   * Edit a dimension for a new pallet
   * @param { string } direction - width, depth, or height
   * @param { string } value - size of the pallet
   */
  editDimension = (direction, value) => {
    let newPallet = Object.assign({}, this.state.newPallet);
    newPallet[direction] = value === "" ? "" : value;
    this.setState({newPallet: newPallet});
  };

  /*
   * User sets the quantity to unpack of a packed part
   * @param { number } id - part.id of the part to update
   * @param { string } value - New numerical value
   */
  setQtyToUnpack = (id, value) => {
    let part = this.state.packedParts.find((part) => part.id === id);

    // Don't mutate state. Copy it, and assign our user's new value
    let newPackedParts = Object.assign([], this.state.packedParts);
    part.userToUnpack = value;
    this.setState({packedParts: newPackedParts});
  };

  /*
   * User sets the quantity kitted of a queued part
   * @param { number } id - part.id of the part to update
   * @param { string } value - New numerical value
   */
  setQtyKitted = (id, value) => {
    // We can only pack the maximum remaining parts
    let part = this.state.requiredParts.find((part) => part.id === id);

    // Don't mutate state. Copy it, and assign our user's new value
    let newRequiredParts = Object.assign([], this.state.requiredParts);
    part.userQtyKitted = value;
    this.setState({requiredParts: newRequiredParts});
  };

  /**
   * User sets the quantity per bag for kitted parts
   *
   * @param { string } id - Unique ID for the required part
   * @param { string } value - User entered value for the required part
   * @return { void }
   */
  editQtyPerBag = (id, value) => {
    let requiredParts = [...this.state.requiredParts];
    let part = requiredParts.find((part) => part.id == id);
    part.userQtyPerBag = value;
    this.setState({requiredParts});
  };

  /*
   * User sets the weight each of a queued part
   * @param { number } id - part.id of the part to update
   * @param { string } value - New numerical value
   */
  setWeightEach = (id, value) => {
    let newRequiredParts = Object.assign([], this.state.requiredParts);
    let part = newRequiredParts.find((part) => part.id === id);
    part.userWeightEach = value;
    this.setState({requiredParts: newRequiredParts});
  };

  toggleModal = () => {
    this.setState({
      partListModalOpen: !this.state.partListModalOpen,
    });
  };

  /*
   * Populate the rows of kitted parts
   */
  populateKittedParts = () => {
    if (this.state.requiredParts.length === 0) {
      return "There doesn't seem to be anything here...";
    }

    // Only let through parts that meet the user's filter
    let filteredKittedParts = this.filterParts();

    return filteredKittedParts.map((part) => {
      let weightFromServer = part.part.weight == 0 ? "" : part.part.weight;
      let qtyPerBag =
        part.userQtyPerBag !== 0 ? part.userQtyPerBag : part.qty_per_bag;
      if (qtyPerBag === 0) {
        qtyPerBag = part.req_qty;
      }
      return (
        <KittedPartRow
          partNumber={part.part.number}
          revision={part.part.revision}
          description={part.part.description}
          kittedQty={part.kitted_qty}
          key={part.id}
          id={part.id}
          onChange={this.setQtyToPack}
          toPack={part.userToPack}
          totalPacked={this.totalPacked(part.part.number, part.part.revision)}
          qtyPerBag={qtyPerBag}
          userWeightEach={part.userWeightEach || weightFromServer}
          setWeightEach={this.setWeightEach}
          editQtyPerBag={this.editQtyPerBag}
          unitOfMeasure={part.part.unitOfMeasure}
        />
      );
    });
  };

  kittedStats = () => {
    return this.state.requiredParts.reduce(
      (acc, part) => {
        acc.sum += part.kitted_qty;
        acc.total += part.req_qty;

        return acc;
      },
      {
        sum: 0,
        total: 0,
      }
    );
  };

  /*
   * Populate the rows of packed parts
   */
  populatePackedParts = () => {
    if (this.state.packedParts.length === 0) {
      return "There doesn't seem to be anything here...";
    }

    // Only let through parts that meet the user's filter
    let filteredPackedParts = this.filterPackedParts();

    return filteredPackedParts.map((part) => {
      let palletId = this.state.allBoxes.find((box) => part.box_id == box.id)
        .pallet_id;
      let pallet = this.state.pallets.find((pallet) => pallet.id == palletId);
      let palletName = null;
      if (pallet) {
        palletName = pallet.name;
      }
      if (part.packed_qty == 0) {
        return;
      }
      return (
        <PackedPartRow
          partNumber={part.requiredpart.part.number}
          revision={part.requiredpart.part.revision}
          description={part.requiredpart.part.description}
          pallet={palletName}
          qty={part.packed_qty - Number(part.userToUnpack)}
          box={this.state.allBoxes.find((box) => part.box_id == box.id).label}
          key={part.id}
          id={part.id}
          onChange={this.setQtyToUnpack}
          userToUnpack={part.userToUnpack}
          unitOfMeasure={part.requiredpart.part.unitOfMeasure}
          itemNumber={part.item_number}
        />
      );
    });
  };

  /*
   * Populate the rows of queued parts
   */
  populateQueuedParts = () => {
    if (this.state.requiredParts.length === 0) {
      return "There doesn't seem to be anything here...";
    }

    // Only let through parts that meet the user's filter
    let filteredQueuedParts = this.filterParts();

    return filteredQueuedParts.map((part) => {
      return (
        <QueuedPartRow
          partNumber={part.part.number}
          revision={part.part.revision}
          description={part.part.description}
          requiredQty={part.req_qty}
          location={part.location}
          bin={part.bin}
          onHand={part.onHand}
          unitsKitted={part.kitted_qty}
          key={part.id}
          id={part.id}
          setQtyKitted={this.setQtyKitted}
          userQtyKitted={part.userQtyKitted}
          unitOfMeasure={part.part.unitOfMeasure}
        />
      );
    });
  };

  populatePartLists = () => {
    if (this.state.partLists.length === 0) {
      return "There doesn't seem to be anything here...";
    }

    return this.state.partLists.map((partList) => {
      return (
        <PartListRow
          partListNumber={partList.id}
          jobOrders={partList.jos}
          id={partList.id}
          key={partList.id}
          shipmentNumber={this.state.id}
        />
      );
    });
  };

  /* Get shipment information for the current page */
  getShipmentInfo = async () => {
    let res = await this.apiQuery(
      `${baseURI}/shipment/${this.state.id}`,
      "GET",
      null,
      `Found information for shipment ${this.state.id}.`,
      `Failed to get information about shipment ${this.state.id}.`
    );
    if (!res.ok) {
      return;
    }

    let json = await res.json();
    this.setState({shipment: json});
    return json;
  };

  /*
   * Get all PartLists associated with a shipment
   */
  getShipmentPartLists = async () => {
    let res = await this.apiQuery(
      `${baseURI}/shipment/${this.state.id}/partlist`,
      "GET",
      null,
      `Found partlists for shipment ${this.state.id}.`,
      `Failed to find partlists for about shipment ${this.state.id}.`
    );
    if (!res.ok) {
      return;
    }

    let json = await res.json();
    this.setState({partLists: json});
    return json;
  };

  getPartLocation = async (number, revision = null) => {
    return this.QueryM2mApi("part_onhand", number, "", "", revision);
  };

  getPartLocations = async (parts) => {
    let partLocations = [];
    parts.forEach((p) =>
      partLocations.push(this.getPartLocation(p.part.number, p.part.revision))
    );

    let res = await Promise.all(partLocations);
    let requiredParts = [...this.state.requiredParts];
    res.forEach((p) => {
      if (p.result.length === 0) {
        return;
      }
      let index = requiredParts.findIndex(
        (requiredPart) =>
          p.result[0].fpartno.replace(/\s{2,}/g, "") == requiredPart.part.number
      );
      if (index === -1) {
        return;
      }

      requiredParts[index].location = p.result[0].flocation.replace(
        /\s{2,}/g,
        ""
      );
      requiredParts[index].bin = p.result[0].fbinno.replace(/\s{2,}/g, "");
      requiredParts[index].onHand = p.result[0].fonhand.replace(/\s{2,}/g, "");
    });

    this.setState({requiredParts});
  };

  /*
   * Get the required parts for a given partlist
   * @param { string } id - PartList id
   */
  getRequiredParts = async (id) => {
    let res = await this.apiQuery(
      `${baseURI}/partlist/${id}/requiredpart`,
      "GET",
      null,
      ``,
      `Failed to find the required parts in partlist ${id} from the server.`
    );
    let json = res.ok ? await res.json() : [];

    this.getPartLocations(json);

    return json.map((part) => {
      return Object.assign({}, part, {
        userToPack: 0,
        userQtyKitted: 0,
        userWeightEach: "",
        userQtyPerBag: part.qty_per_bag,
        partlist_id: id,
      });
    });
  };

  /*
   * Get the packed parts for a given shipment
   * @param { string } id - partlist id
   */
  getPackedParts = async () => {
    let res = await this.apiQuery(
      `${baseURI}/shipment/${this.state.id}`,
      "GET",
      null,
      "",
      "Failed to find the packed parts for this shipment."
    );
    if (!res.ok) {
      return;
    }

    let json = await res.json();
    let packedParts = [];
    let allBoxes = [];
    for (let pallet of json.pallets) {
      for (let box of pallet.boxes) {
        allBoxes.push(box);
        for (let packedPart of box.packed_parts) {
          packedParts.push(Object.assign({}, packedPart, {userToUnpack: ""}));
        }
      }
    }

    this.setState({
      packedParts: packedParts,
      allBoxes: allBoxes,
    });
  };

  /*
   * Get all required parts for every PartList
   */
  getAllRequiredParts = async () => {
    let partPromises = [];
    for (let part of this.state.partLists) {
      partPromises.push(this.getRequiredParts(part.id));
    }

    // We need to flatted the response, as it's an array of arrays of parts
    let response = await Promise.all(partPromises);

    this.setState({requiredParts: [].concat.apply([], response)});
  };

  /*
   * Get all pallets associated with this shipment
   */
  getPallets = async () => {
    let res = await this.apiQuery(
      `${baseURI}/shipment/${this.state.id}/pallet`,
      "GET",
      null,
      "",
      "Failed to retrieve the pallets."
    );
    let json = res.ok ? await res.json() : [];

    this.setState({pallets: json});
  };

  /*
   * Get all boxes associated with this shipment
   */
  getBoxes = async () => {
    let palletId = this.state.pallets.find(
      (pallet) => pallet.name == this.state.focusedPallet
    ).id;
    let res = await this.apiQuery(
      `${baseURI}/pallet/${palletId}/box`,
      "GET",
      null,
      `Successfully found boxes associated with pallet ${this.state.focusedPallet}.`,
      `Failed to find the boxes for pallet ${this.state.focusedPallet}.`
    );
    let json = res.ok ? await res.json() : [];

    this.setState({boxes: json});
  };

  /*
   * Get total quantity packed for a given part
   * @param { string } partNumber - Part identifier
   * @param { rev } rev - Revision number for the given part
   */
  totalPacked = (partNumber, rev) => {
    let packed = 0;
    if (!this.state.shipment) {
      return 0;
    }
    for (let pallet of this.state.shipment.pallets) {
      for (let box of pallet.boxes) {
        for (let packedPart of box.packed_parts) {
          if (
            packedPart.requiredpart.part.number === partNumber &&
            packedPart.requiredpart.part.revision === rev
          ) {
            packed += Number(packedPart.packed_qty);
          }
        }
      }
    }

    return packed;
  };

  /*
   * Get total quantity kitted for a given part
   * @param { string } partNumber - Part identifier
   * @param { rev } rev - Revision number for the given part
   */
  unitsKitted = (partNumber, rev) => {
    let kitted = 0;
    for (let pallet of this.state.shipment.pallets) {
      for (let box of pallet.boxes) {
        for (let packedPart of box.packed_parts) {
          if (
            packedPart.requiredpart.part.number === partNumber &&
            packedPart.requiredpart.part.revision === rev
          ) {
            kitted += packedPart.requiredpart.kitted_qty;
          }
        }
      }
    }

    return kitted;
  };

  /*
   * Create a new box for the given shipment
   */
  createBox = async () => {
    let boxLabel = String.fromCharCode(65 + this.state.boxes.length);
    let boxRes = await this.apiQuery(
      `${baseURI}/box`,
      "POST",
      JSON.stringify({
        label: boxLabel,
      }),
      "Successfully created box a new box.",
      "Failed to create a new box."
    );
    if (!boxRes.ok) {
      return;
    }

    // Once we've created the new box, we should associate it with the currently
    // selected pallet
    let box = await boxRes.json();

    let palletId = this.state.pallets.find(
      (pallet) => pallet.name == this.state.focusedPallet
    ).id;
    let palletRes = await this.apiQuery(
      `${baseURI}/box/${box.id}/pallet/${palletId}`,
      "POST",
      null,
      `Successfully associated box ${box.label} with pallet ${this.state.focusedPallet}.`,
      `Failed to associate box ${box.label} with pallet ${this.state.focusedPallet}.`
    );
    if (!palletRes.ok) {
      return;
    }

    // Once that association is finished, we should refresh our boxes list
    await this.getBoxes();
    this.setState({
      focusedBox: boxLabel,
    });

    return palletRes;
  };

  /*
   * Creates new pallets/boxes depending on whether the user selected
   * new pallet or new box
   */
  handlePalletBoxCreation = async () => {
    // If there's a new pallet, we must create a new pallet and new box, but
    // if we're on an existing pallet, we only need create a new box if the
    // user has specified that option
    if (this.state.focusedPallet === "New Pallet") {
      await this.createPallet();
      await this.createBox();
    } else if (this.state.focusedBox === "New Box") {
      await this.createBox();
    }
  };

  /*
   * Updates/creates the Kitted Parts records via the API
   * @param {number} id - id of the required part to update
   */
  updateKittedPart = async (id) => {
    let part = this.state.requiredParts.find((part) => part.id === id);
    let box = this.state.boxes.find(
      (box) => box.label === this.state.focusedBox
    );
    let boxId = box ? box.id : null;

    /* Form body */
    let body = {requiredpart_id: part.id};
    if (part.userToPack) {
      body.packed_qty = part.userToPack;
    }
    if (boxId) {
      body.box_id = boxId;
    }
    if (part.userQtyPerBag) {
      body.qty_per_bag = part.userQtyPerBag;
    }
 
    body.weight = part.userWeightEach || part.part.weight;

    /* Update packed part */
    let res = await this.apiQuery(
      `${baseURI}/packedpart`,
      "POST",
      JSON.stringify(body),
      `Successfully updated kitted part ${part.part.number} | ${part.part.revision}.`,
      `Failed to update kitted part ${part.part.number} | ${part.part.revision}.`
    );

    return res;
  };

  /*
   * Iterate through the packed parts, PATCHing parts that the user
   * has edited
   */
  updateKittedParts = async () => {
    await this.handlePalletBoxCreation();
    let kittedToUpdate = [];
    for (let part of this.state.requiredParts) {
      if (part.userToPack !== 0) {
        kittedToUpdate.push(this.updateKittedPart(part.id));
      }
    }

    let responses = await Promise.all(kittedToUpdate);

    await this.getShipmentPartLists();
    this.getAllRequiredParts();
    this.getPackedParts();
    this.getShipmentInfo();
  };

  /*
   * Updates the quantity to unpack of a given packed part
   * @param { string } id - Packed part id
   * @param { string } method - What do: PATCH or DELETE the part?
   */
  updatePackedPart = async (id, method) => {
    let part = this.state.packedParts.find((part) => part.id === id);

    let patchBody = JSON.stringify({
      packed_qty: part.packed_qty - Number(part.userToUnpack),
    });
    const postBody = JSON.stringify({
      weight: part.userWeightEach || part.requiredpart.part.weight,
    });
    let res = await this.apiQuery(
      `${baseURI}/packedpart/${id}`,
      method,
      "PATCH" ? patchBody : postBody,
      `Successfully updated packed part ${part.requiredpart.part.number} | ${part.requiredpart.part.revision}.`,
      `Failed to update packed part ${part.requiredpart.part.number} | ${part.requiredpart.part.revision}.`
    );

    return res;
  };

  /*
   * Iterate through all of the kitted parts, PATCHing/DELETEing parts
   */
  updatePackedParts = async () => {
    let packedToUpdate = [];
    for (let part of this.state.packedParts) {
      if (part.userToUnpack !== "") {
        let method = part.userToUnpack == part.packed_qty ? "DELETE" : "PATCH";
        packedToUpdate.push(this.updatePackedPart(part.id, method));
      }
    }

    this.getPackedParts();
    this.getAllRequiredParts();
    this.getShipmentInfo();
  };

  /*
   * Iterate through the queued parts, PATCHing parts that the user
   * has edited
   */
  updateQueuedParts = async () => {
    let queuedToUpdate = [];
    for (let part of this.state.requiredParts) {
      if (
        part.userQtyKitted !== 0 ||
        (part.userWeightEach !== 0 && part.userWeightEach !== "")
      ) {
        queuedToUpdate.push(this.updateQueuedPart(part.id));
      }
    }

    await Promise.all(queuedToUpdate);
    this.getPackedParts();
    this.getAllRequiredParts();
    this.getShipmentInfo();
  };

  /*
   * Updates the kitted quantity or weight each of the queued part
   * @param { string } id - Queued part id
   */
  updateQueuedPart = async (id) => {
    let part = this.state.requiredParts.find((part) => part.id === id);
    let body = JSON.stringify({
      kitted_qty: Number(part.userQtyKitted),
      weight: part.userWeightEach || part.part.weight
    });
    let res = await this.apiQuery(
      `${baseURI}/requiredpart/${id}`,
      "PATCH",
      body,
      `Successfully updated packed part ${part.part.number} | ${part.part.revision}.`,
      `Failed to update packed part ${part.part.number} | ${part.part.revision}.`
    );
  };

  rolloverRequiredParts = async () => {
    if (this.state.shipment.rolled_over) {
      let result = window.confirm(
        "This shipment has already been rolled over. Are you sure you want to roll over parts again?"
      );
      if (!result) return;
    }

    let res = await this.apiQuery(
      `${baseURI}/shipment/${this.state.shipment.id}/rollover`,
      "POST",
      null,
      "Rolled over parts!",
      "Failed to roll over parts!"
    );
    if (!res.ok) return;

    let shipList = await res.json();
    this.props.history.push(`/partlist/show/${shipList.id}`);
  };

  render = () => {
    let lastModified = null;
    let shipByDate = null;
    let dateOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    if (this.state.shipment) {
      let shipDate = new Date(this.state.shipment.ship_by_date);
      let modifiedDate = new Date(this.state.shipment.modified);
      lastModified =
        `Shipment details last modified: ` +
        (this.state.shipment.modified
          ? `${modifiedDate.toLocaleDateString("en-us", dateOptions)}`
          : "never.");
      shipByDate = `Ship by: ${shipDate.toLocaleDateString(
        "en-us",
        dateOptions
      )}`;
    }

    return (
      <div className="shipment-show">
        {this.state.partListModalOpen ? (
          <PartListModal close={this.toggleModal} copy={this.copyPartList}/>
        ) : null}
        <Status
          isLoading={this.state.isLoading}
          success={this.state.fetchSuccess}
          message={this.state.fetchMsg}
        />
        {
          this.state.shipment?.rolled_over &&
            <div className="rollover-warning">
              <h2>
                Shipment has been rolled over!
              </h2>
              <p>
                Kitted parts were not moved. Any queued parts have been rolled over to a new parts list.
              </p>
            </div>
        }
        <div className="shipment-summary">
          <div className="shipment-title">
            Shipment: {this.props.match.params.id}
            <div className="partlist-container">
              <div className="ship-by-date">
                <strong>{shipByDate}</strong>
              </div>
              <div className="last-modified">{lastModified}</div>
              <div className="partlists">
                <label>PartLists</label>
                {this.populatePartLists()}
              </div>
              <div className="button-panel">
                <SubmitBtn
                  type="save"
                  onClick={() =>
                    this.props.history.push(`/partlist/create#${this.state.id}`)
                  }
                >
                  New PartList
                </SubmitBtn>
                <SubmitBtn type="edit" onClick={this.toggleModal}>
                  Copy Existing PartList
                </SubmitBtn>
                <SubmitBtn type="create" onClick={this.getExcelSummary}>
                  Excel Summary
                </SubmitBtn>
                <SubmitBtn type="create" onClick={this.getExcelInvoice}>
                  Excel Invoice
                </SubmitBtn>
                <SubmitBtn
                  type="create"
                  onClick={() =>
                    this.props.history.push(`/shipment/labels/${this.state.id}`)
                  }
                >
                  Labels
                </SubmitBtn>
                <Link to={`/shipment/${this.props.match.params.id}/pick-list`}>
                  <SubmitBtn type="create">Collated Part List</SubmitBtn>
                </Link>
                <SubmitBtn type="create" onClick={this.rolloverRequiredParts}>
                  Rollover Parts
                </SubmitBtn>
              </div>
            </div>
          </div>
        </div>
        <div className="shipments-row">
          <input
            className="filter-shipment-parts"
            placeholder="Filter by part number or description..."
            onChange={(e) => {
              this.setPartsFilter(e.target.value);
            }}
          />
          <div className="ship-lists-dropdown">
            <label id="ship-lists-label">Filter by PartList</label>
            <Dropdown
              type="ship-lists"
              options={[
                "All",
                ...this.state.partLists.map((partList) => partList.id),
              ]}
              onChange={(e) => this.selectPartList(e.target.value)}
            />
          </div>
        </div>
        <div className="queued-parts-container">
          <label className="queued-parts">Queued Parts</label>
          <Labels
            labels={this.queuedPartLabels}
            sort={this.setSortFilter}
            selectedSort={this.state.sort}
            sortDescending={this.state.sortDescending}
            object="requiredParts"
            selectedObject={this.state.selectedObject}
          />
          {this.populateQueuedParts()}
          <SubmitBtn type="save" onClick={this.updateQueuedParts}>
            Update
          </SubmitBtn>
        </div>
        <div className="kitted-parts-container">
          <label className="kitted-parts">Kitted Parts</label>
          <Labels
            labels={this.kittedPartLabels}
            sort={this.setSortFilter}
            selectedSort={this.state.sort}
            sortDescending={this.state.sortDescending}
            object="requiredParts"
            selectedObject={this.state.selectedObject}
          />
          {this.populateKittedParts()}
          <div className="update-kitted-parts">
            <div className="pallet">
              <label className="pallet-dropdown">Pallet</label>
              <Dropdown
                type="pallets"
                options={[
                  "New Pallet",
                  ...this.state.pallets.map((pallet) => pallet.name),
                ]}
                onChange={(e) => this.selectPallet(e.target.value)}
                value={this.state.focusedPallet}
              />
              <PalletAdd
                editDimension={this.editDimension}
                createPallet={this.createPallet}
                dimensions={this.state.newPallet}
                disabled={
                  this.state.newPallet.depth === "" ||
                  this.state.newPallet.width === "" ||
                  this.state.newPallet.height === ""
                }
              />
            </div>
            <div className="boxes">
              <label className="boxes-dropdown">Box</label>
              <Dropdown
                type="boxes"
                options={[
                  "New Box",
                  ...this.state.boxes.map((box) => box.label),
                ]}
                onChange={(e) => this.selectBox(e.target.value)}
                value={this.state.focusedBox}
              />
            </div>
            <SubmitBtn
              type="save"
              onClick={this.updateKittedParts}
              disabled={
                this.state.isLoading ||
                (this.state.focusedPallet === "New Pallet" &&
                  (this.state.newPallet.width === "" ||
                    this.state.newPallet.depth === "" ||
                    this.state.newPallet.height === ""))
              }
            >
              Update
            </SubmitBtn>
          </div>
        </div>
        <div className="packed-parts-container">
          <label className="packed-parts">Packed Parts</label>
          <Labels
            labels={this.packedPartLabels}
            sort={this.setSortFilter}
            selectedSort={this.state.sort}
            sortDescending={this.state.sortDescending}
            object="packedParts"
            selectedObject={this.state.selectedObject}
          />
          {this.populatePackedParts()}
          <SubmitBtn type="save" onClick={this.updatePackedParts}>
            Update
          </SubmitBtn>
        </div>
      </div>
    );
  };
}

export default ShipmentShow;
