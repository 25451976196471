import { getAuthHeader, getIdToken, login } from "./auth";
import { pushRedirect } from "./redirect";

function apiQuery(
  endpoint,
  method = "GET",
  body,
  successMsg = "Successfully updated!",
  failureMsg = "Failed to update."
) {
  return new Promise((fulfill, reject) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    if (localStorage) {
      headers.set("Authorization", getAuthHeader().Authorization);
    }

    // Trigger loading icon
    this.setLoadingStatus();

    let payload = {
      method: method,
      headers: headers,
    };
    if (body) {
      payload.body = body;
    }

    fetch(endpoint, payload).then((response) => {
      if (response.status === 401) {
        pushRedirect(window.location.href);
        login();
      }
      this.setLoadingStatus(response, successMsg, failureMsg);
      fulfill(response);
    });
  });
}

export default apiQuery;
