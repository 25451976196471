import React, { PureComponent } from "react";

class Labels extends PureComponent {
  constructor(props) {
    super(props);
  }

  render = () => (
    <div className="sort-labels">
      {this.props.labels.map((l) => (
        <div
          className={
            `sort-label` +
            (l.filter &&
            l.filter === this.props.selectedSort &&
            this.props.selectedObject === this.props.object
              ? " selected"
              : "") +
            (l.filter ? "" : " cannot-select")
          }
          onClick={
            l.filter ? () => this.props.sort(l.filter, this.props.object) : null
          }
          key={l.label}
        >
          <span className="sort-symbol">
            {this.props.sortDescending ? "↑ " : "↓ "}
          </span>
          <label>{l.label}</label>
        </div>
      ))}
    </div>
  );
}

/**
 * Set sorting for the parts
 * @param { string } sort - How to filter the parts
 * @param { string } stateObjName - Which object are we sorting on?
 */
function setSortFilter(sort, stateObjName) {
  let newState = {
    sort: sort,
    selectedObject: stateObjName,
  };
  newState.sortDescending = !this.state.sortDescending;

  let parts = [...this.state[stateObjName]];
  parts = this.state[stateObjName].sort((a, b) => {
    if (typeof eval(`a.${sort}`) == "number") {
      if (this.state.sortDescending) {
        return eval(`b.${sort}`) - eval(`a.${sort}`);
      } else {
        return eval(`a.${sort}`) - eval(`b.${sort}`);
      }
    } else {
      if (this.state.sortDescending) {
        return eval(`b.${sort}`) > eval(`a.${sort}`);
      } else {
        return eval(`a.${sort}`) > eval(`b.${sort}`);
      }
    }
  });

  newState[stateObjName] = parts;
  this.setState(newState);
}

export { Labels, setSortFilter };
