import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "../Authentication/Login";
import SingleSignIn from "../Authentication/SingleSignIn";
import { connect } from "react-redux";

import Navbar from "./Navbar";
import Landing from "./Landing";
import About from "./About";
import PartShow from "./PartShow";
import PartList from "./PartList";
import Shipment from "./Shipment";
import PickList from "./PickList/PickList";
import ShipmentLabels from "./ShipmentLabels";
import { pushRedirect } from "../../helpers/redirect";
import { login, clearLocalProfileState } from "../../helpers/auth";
import CreatePart from "../CreatePart/CreatePart";

/*
 * Root of the component tree which contains the Part Show, Shipment, and PartList
 * components.
 */
class App extends Component {
  componentDidMount = async () => {
    if (!this.props.isAuthenticated) this.triggerLogin();
  };

  componentWillReceiveProps = (nextProps) => {
    if (!nextProps.isAuthenticated) this.triggerLogin();
  };

  triggerLogin = () => {
    pushRedirect(window.location.href);
    login();
  };

  render() {
    return (
      <div className="app">
        {/*
         * Switch only renders the first child that matches the path,
         * so if we hit the labels page, the navbar won't render.
         */}
        <Switch>
          <Route exact path="/shipment/labels/:id" component={ShipmentLabels} />
          <Route exact path="/partlist/labels/:id" component={ShipmentLabels} />
          <Route path="/" component={Navbar} />
        </Switch>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/about" component={About} />
          <Route path="/part" component={PartShow} />
          <Route path="/part-creation" component={CreatePart} />
          <Route path="/partlist" component={PartList} />
          <Route path="/shipment" component={Shipment} />
        </Switch>
        <Route exact path="/shipment/:id/pick-list" component={PickList} />
        <Route path="/callback" component={Login} />
        <SingleSignIn />
      </div>
    );
  }
}

const mapState = (state) => ({
  profile: state.profile,
  isAuthenticated:
    (state.profile &&
      state.profile.expiresAt &&
      state.profile.expiresAt > Date.now()) ||
    false,
});

const mapDispatch = (dispatch) => ({
  handleLogout: () => {
    dispatch({
      type: "CLEAR_PROFILE",
    });
    clearLocalProfileState();
  },
});

export default connect(mapState, mapDispatch)(App);
