import React, { PureComponent } from "react";

class PackedPartLabel extends PureComponent {
  render = () => {
    return (
      <div className="label">
        <center>
          <div className="label-top-row" id="">
            <img className="label-logo" src={this.props.wassonLogo} />
            <div className="label-barcode-wrap" id="">
  {this.props.partNumber}
            </div>
            <br />
          </div>
          <div className="label-clear" id="" />
          <br />
          <p className="label-description">{this.props.description}</p>
          <div className="label-lower">
            <div className="label-address-wrap">
              <p className="label-address">
                101 Rome Ct. Fort Collins, CO USA
                <br />
                +1-970-221-9179 service@wasson-ece.com
              </p>
            </div>
            <table className="label-info-table">
              <tr>
                <td>Pallet: {this.props.pallet_name}</td>
                <td>Item: {this.props.item}</td>
              </tr>
              <tr>
                <td>Box: {this.props.box_label}</td>
                <td>Qty: {this.props.qty}</td>
              </tr>
            </table>
          </div>
          <div className="label-clear" />
        </center>
      </div>
    );
    // Yes I feel shame about this not being proper react and css
  };
}

export default PackedPartLabel;
